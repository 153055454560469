(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('usersTable', {
            template: require('./UsersTableComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['TrainingManagerTabService', UsersTableController],
            bindings: {
                performanceValues: '<',
                users: '<'
            }
        });

    function UsersTableController(TrainingManagerTabService) {
        var ctrl = this,
            COLUMN = {
                NAME: {
                    title: 'TRAINING.NAME',
                    key: 'UserName'
                },
                COMPLETION: {
                    title: 'TRAINING.COMPLETION_RATE',
                    key: 'CompletionRate'
                },
                PERFORMANCE: {
                    title: 'TRAINING.PERFORMANCE_SCORE',
                    key: 'PerformanceScore'
                }
            };

        ctrl.COLUMN = COLUMN;
        ctrl.columns = [COLUMN.NAME, COLUMN.COMPLETION, COLUMN.PERFORMANCE];
        ctrl.setPerformanceClass = TrainingManagerTabService.setPerformanceClass;

        ctrl.$onInit = init;
        ctrl.sort = sort;
        ctrl.openTrainingOverview = openTrainingOverview;
        ctrl.paging = paging;

        ctrl.$onChanges = function (changes) {
            if (changes.users) {
                init();
            }
        };

        function init() {
            ctrl.usersLimit = _.take(ctrl.users, 20);
        }

        function sort(column) {
            ctrl.isReverse = (ctrl.selectedProperty === column.key) ? !ctrl.isReverse : false;
            ctrl.selectedProperty = column.key;
        }

        function openTrainingOverview(user) {
            ctrl.loadinguser = true;
            TrainingManagerTabService.openTrainingOverviewPopup(user).then(() => {
                ctrl.loadinguser = false;
            });
        }

        function paging() {
            ctrl.usersLimit = _.take(ctrl.users, ctrl.usersLimit.length + 20);
        }
    }
})();