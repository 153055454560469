(() => {
    'use strict';

    angular.module('App.Training')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('trainingCategory', {
                parent: 'base',
                url: '/training/category/:token',
                template: '<training-voluntary-category class="view-page" modules="ctrl.modules" related-categories="ctrl.relatedCategories"' +
                    'child-categories="ctrl.childCategories" color="ctrl.color" module-token="ctrl.moduleToken" image="ctrl.image"></training-voluntary-category>',
                resolve: {
                    categoryData: ['TrainingVoluntaryService', '$stateParams', 'base',
                        function (TrainingVoluntaryService, $stateParams, base) {
                            if (base) {
                                return TrainingVoluntaryService.getRelated($stateParams.token);
                            }
                        }]
                },
                params: {
                    backState: null,
                    data: {}
                },
                controller: 'TrainingCategoryController as ctrl'
            });
        }])
        .controller('TrainingCategoryController',
            ['$stateParams', '$scope', 'categoryData', 'events', 'Page', TrainingCategoryController]);

    function TrainingCategoryController($stateParams, $scope, categoryData, events, Page) {
        var ctrl = this;

        ctrl.relatedCategories = categoryData.RelatedCategories;
        ctrl.childCategories = categoryData.ChildCategories;
        ctrl.modules = categoryData.Modules;
        ctrl.color = categoryData.Color;
        ctrl.image = categoryData.CategoryImage;
        ctrl.moduleToken = categoryData.AccountModuleToken;
        
        if ($stateParams.backState) {
            Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra, null, $stateParams.backState.options);
        } else {
            Page.showBackButton('training', categoryData.AccountModuleToken, 'voluntary');
        }
        Page.setTitle(categoryData.PageTitle);

        $scope.$on(events.DEPARTMENT_CHANGED, function () {
            Page.stateGoBack('training', categoryData.AccountModuleToken);
        });
    }

})();