(function () {
    'use strict';
    var app = angular.module('App.Elements');

    app.factory('LoadingService', ['$rootScope', '$compile', '$animate', '$q', '$document', '$timeout', 'Page',
        function ($rootScope, $compile, $animate, $q, $document, $timeout, Page) {

            return {
                create: function () {
                    var hasAnimated = false;
                    var $scope = $rootScope.$new(true);
                    var $element = $compile('<loading></loading>')($scope);

                    var returnObj = {
                        show: function () {
                            var d = $q.defer();

                            // Adjust colors for the overlay to match for every device
                            Page.overlayColorAdjustment('#000', '#fff');

                            $animate.enter($element, $document[0].body, $document[0].body.lastChild).then(function () {
                                hasAnimated = true;
                                d.resolve();
                            });
                            return d.promise;
                        },
                        dismiss: function () {
                            var d = $q.defer();

                            // Revert overlay device color adjustments
                            Page.revertOverlayColorAdjustment();

                            if (hasAnimated) {
                                $animate.leave($element).then(function () {
                                    d.resolve();
                                });
                            } else {
                                $timeout(function () {
                                    returnObj.dismiss().then(function () {
                                        d.resolve();
                                    });
                                }, 100);
                            }

                            return d.promise;
                        }
                    };

                    return returnObj;
                }
            };
        }
    ]);
})();