(() => {
    'use strict';

    angular
        .module('App.Training')
        .controller('TrainingController', ['$scope', '$state', '$stateParams', '$filter', 'trainingSettings', 'Page',
            'TrainingService', 'HeaderButtonsFactory', 'TRAINING_ROUTE', TrainingController]);

    function TrainingController($scope, $state, $stateParams, $filter, trainingSettings, Page, TrainingService,
                                HeaderButtonsFactory, TRAINING_ROUTE) {
        const ctrl = this;

        ctrl.trainingSettings = trainingSettings;
        ctrl.state = $state.current.name;

        Page.setTitle(
            ctrl.trainingSettings.PageTitle,
            $filter('translate')(TrainingService.trainingRoutesTitles[ctrl.state])
        );

        TrainingService.setHeaderButtons(ctrl.trainingSettings);

        if ($stateParams.backState && $stateParams.backState.state !== TRAINING_ROUTE.PARENT) {
            Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
        } else {
            Page.showBackButton('home');
        }

        $scope.$on('$destroy', () => HeaderButtonsFactory.resetButtonsList());
    }
})();
