(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('trainingMaterialSelector', {
            template: require('./TrainingMaterialSelectorComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$compile', '$templateRequest', '$timeout', TrainingMaterialSelectorController],
            bindings: {
                courses: '<',
                modules: '<',
                buttonId: '@',
                course: '=',
                module: '='
            }
        })

    function TrainingMaterialSelectorController($scope, $compile, $templateRequest, $timeout) {
        var ctrl = this, popup, popupEl, isPopupOpen;

        ctrl.VIEW = {
            COURSE: 1,
            MODULE: 2
        }

        ctrl.$onInit = init;
        ctrl.selectTab = selectTab;
        ctrl.onSearch = onSearch;
        ctrl.selectCourse = selectCourse;
        ctrl.selectModule = selectModule;
        ctrl.openSelector = openSelector;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.close = close;
        ctrl.loadCourses = loadCourses;
        ctrl.loadModules = loadModules;


        function init() {
            ctrl.visibleCourses = ctrl.courses;
            ctrl.visibleModules = ctrl.modules;
            initActiveTab();
        }


        function loadCourses() {
            if (ctrl.coursesLimit <= ctrl.visibleCourses.length) {
                ctrl.moreCourses = true;
                ctrl.coursesLimit += 20;
            } else {
                ctrl.moreCourses = false
            }
        }

        function loadModules() {
            if (ctrl.modulesLimit <= ctrl.visibleModules.length) {
                ctrl.moreModules = true;
                ctrl.modulesLimit += 20;
            } else {
                ctrl.moreModules = false
            }
        }

        function initActiveTab() {
            if (ctrl.module) {
                ctrl.currentView = ctrl.VIEW.MODULE;
            } else {
                ctrl.currentView = ctrl.VIEW.COURSE;
            }
        }

        function selectTab(view) {
            ctrl.searchString && resetSearch();
            ctrl.currentView = view;
        }

        function onSearch(string) {
            if (ctrl.currentView === ctrl.VIEW.COURSE) {
                ctrl.visibleCourses = _.filter(ctrl.courses, function (course) {
                    return course.Title.toLowerCase().indexOf(string.toLowerCase()) !== -1
                })
            } else {
                ctrl.visibleModules = _.filter(ctrl.modules, function (module) {
                    return module.Title.toLowerCase().indexOf(string.toLowerCase()) !== -1
                })
            }
            resetSearchLimit();
        }

        function resetSearch() {
            ctrl.searchString = '';
            onSearch(ctrl.searchString);
        }

        function selectCourse(course) {
            resetSelection()
            course.selected = true;
            ctrl.course = course;
            ctrl.module = null;
            close();
        }

        function selectModule(module) {
            resetSelection()
            module.selected = true;
            ctrl.module = module;
            ctrl.course = null;
            close();
        }

        function resetSelection() {
            var courseIndex = _.findIndex(ctrl.courses, {selected: true}),
                moduleIndex = _.findIndex(ctrl.modules, {selected: true});

            courseIndex !== -1 && delete ctrl.courses[courseIndex].selected
            moduleIndex !== -1 && delete ctrl.modules[moduleIndex].selected
        }

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open()
            isPopupOpen = true;
            initActiveTab();
            resetSearchLimit();
        }

        function close() {
            popup.remove();
            popupEl.remove()
            resetSearch();
            isPopupOpen = false;
        }

        function resetSearchLimit() {
            ctrl.coursesLimit = 20;
            ctrl.modulesLimit = 20;
            ctrl.moreCourses = true;
            ctrl.moreModules = true;
        }

        function openSelector() {
            const template = angular.element(require('./TrainingMaterialSelectorPopup.html'));
            popupEl = $compile(template)($scope);
            angular.element('body').append(popupEl);
        }
    }
})();