(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('trainingLearnMoreComponent', {
            template: require('./TrainingLearnMoreComponent.html'),
            controllerAs: 'ctrl',
            bindings: {
                onLearnMoreClick: '<'
            }
        })
})();