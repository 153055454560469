(() => {
    angular
        .module('App.Social')
        .controller('SocialOverviewController', ['$stateParams', 'socialSettings', 'Page', SocialOverviewController])

    function SocialOverviewController($stateParams, socialSettings, Page) {
        const ctrl = this;

        ctrl.socialSettings = socialSettings;

        if ($stateParams.backState != null) {
            Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
        } else {
            Page.showBackButton('home');
        }

        Page.setTitle(socialSettings.PageTitle, 'SOCIAL.GROUPS.SELECT');
    }
})();