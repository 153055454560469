(function () {
    'use strict';

    angular
        .module('App.Catalog')
        .component('catalogItemsList', {
            template: require('./CatalogItemsListComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$stateParams', 'Page', CatalogItemsListController],
            bindings: {
                items: '<',
                settings: '<',
                catalogCategoryToken: '<',
                searchString: '@'
            }
        });

    function CatalogItemsListController($scope, $stateParams, Page) {
        const ctrl = this;

        ctrl.paging = paging;
        ctrl.goToItem = goToItem;

        $scope.$watchCollection('ctrl.items', function (newCollection) {
            if (newCollection) {
                ctrl.renderItems = _.take(newCollection, 24);
                ctrl.isScrollDisabled = false;
            }
        });

        function paging() {
            ctrl.renderItems = _.take(ctrl.items, ctrl.renderItems.length + 6);
            ctrl.isScrollDisabled = ctrl.renderItems.length === ctrl.items.length;
        }

        function goToItem(item) {
            Page.stateGo(
                'itemDetails',
                $stateParams.token,
                item.Item.DataListItemToken,
                Page.getPageTransition(null, null),
                ctrl.catalogCategoryToken
            );
        }
    }
})();