(() => {
    angular
        .module('App.Social')
        .config(['$stateProvider', ($stateProvider) => {
            $stateProvider
                .state('social', {
                    parent: 'base',
                    url: '/socialWall/:token/:extra',
                    template: '<social class="view-page social-page" posts-data="postsData" open-create-popup="openCreatePopup"></social>',
                    params: {
                        data: {},
                        backState: null
                    },
                    resolve: {
                        socialSettings: ['$stateParams', 'SocialGroupService', 'SocialService', 'Page',
                            ($stateParams, SocialGroupService, SocialService, Page) => {
                                const token = $stateParams.token;
                                const stateData = $stateParams.data;
                                const socialGroupParsed = SocialGroupService.validateSocialGroupId(token, $stateParams.extra, true);

                                return SocialService.getSettings(token, socialGroupParsed.socialGroupId, true, socialGroupParsed.isCachedValue).then(settings => {
                                    const socialGroupId = SocialGroupService.validateSocialGroupIdFromData(token, settings.SocialGroups, socialGroupParsed.socialGroupId);
                                    const stateGoOverview = () => {
                                        Page.stateGo('socialOverview', token, socialGroupId, $stateParams.backState, {
                                            ...settings,
                                            isPreloaded: true
                                        });
                                    }
                                    const showSocialWall = typeof (socialGroupId) !== 'undefined' ||
                                        stateData.openGroup || settings.SelectedSocialGroupId ||
                                        !(settings.IsGroupViewEnabled && settings.IsSharedView);

                                    if (settings.OpenGroupOverviewByDefault && !stateData.openGroup && settings.SocialGroups?.length > 1) {
                                        stateGoOverview();
                                        return;
                                    }

                                    if (showSocialWall) {
                                        return {...settings, SelectedSocialGroupId: socialGroupId};
                                    } else {
                                        stateGoOverview();
                                    }
                                })
                            }]
                    },
                    controller: 'SocialController as SocialCtrl'
                })
                .state('socialOverview', {
                    parent: 'base',
                    url: '/socialOverview/:token',
                    template: `<social-groups class="view-page social-page" data="ctrl.socialSettings"></social-groups>`,
                    params: {
                        data: {},
                        backState: null
                    },
                    resolve: {
                        socialSettings: ['$stateParams', 'SocialService', ($stateParams, SocialService) => {
                            if ($stateParams.data?.isPreloaded) {
                                return { ...$stateParams.data, noBadges: true }
                            } else {
                                return SocialService.getSettings($stateParams.token, null, true, false)
                            }
                        }]
                    },
                    controller: 'SocialOverviewController as ctrl'
                })
                .state('socialPost', {
                    parent: 'base',
                    url: '/socialPost/:token/:extra',
                    params: {
                        data: {},
                        backState: null
                    },
                    template: require('./SocialWall/Post/SocialPost.tpl.html'),
                    resolve: {
                        post: ['$http', '$stateParams', ($http, $stateParams) => {
                            return $http.get('/Social/GetPostV2/' + $stateParams.token + $stateParams.extra);
                        }]
                    },
                    controller: 'SocialPostController as ctrl'
                });
        }]);
})();