(function () {
    'use strict';

    angular
        .module('App.Basics')
        .constant('NATIVE_BIOMETRIC_STATUS', {
            'ERROR': 'ERROR',
            'SUCCESS': 'SUCCESS',
            'AVAILABLE': 'AVAILABLE',
            'STORED': 'STORED',
            'CANCELLED': 'CANCELLED'
        })
        .constant('NATIVE_BIOMETRIC_ERROR_CODES', {
            'BIOMETRIC_CHANGED': 'BIOMETRIC_CHANGED',
            'BIOMETRIC_NOT_AVAILABLE': 'BIOMETRIC_NOT_AVAILABLE',
            'BIOMETRIC_AUTHENTICATION_FAILED': 'BIOMETRIC_AUTHENTICATION_FAILED'
        })
        .constant('NATIVE_BIOMETRIC_ERROR_LABELS', {
            'BIOMETRIC_CHANGED': 'NATIVE.ERROR.BIOMETRIC_CHANGED',
            'BIOMETRIC_NOT_AVAILABLE': 'NATIVE.ERROR.BIOMETRIC_NOT_AVAILABLE',
            'BIOMETRIC_SAVE_FAILED': 'NATIVE.ERROR.BIOMETRIC_SAVE_FAILED',
            'BIOMETRIC_UNKNOWN_ERROR': 'NATIVE.ERROR.BIOMETRIC_UNKNOWN_ERROR',
            'BIOMETRIC_AUTHENTICATION_FAILED': 'NATIVE.ERROR.BIOMETRIC_AUTHENTICATION_FAILED'
        })
})();