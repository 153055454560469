(() => {
    'use strict';

    angular.module('App.Training')
        .component('trainingIntroduction', {
            template: require('./TrainingIntroductionComponent.html'),
            controllerAs: 'ctrl',
            controller: [IntroductionComponentController],
            bindings: {
                data: '<',
                type: '@'
            }
        });

    function IntroductionComponentController() {
        var ctrl = this, introductionToggleKey, tokenKey;

        ctrl.toggleIntroduction = toggleIntroduction;
        ctrl.$onInit = init;

        function init() {
            if (ctrl.type === 'course') {
                introductionToggleKey = 'trainingCourseIntroduction_';
                tokenKey = 'CourseToken';
            }
            if (ctrl.type === 'module') {
                introductionToggleKey = 'trainingModuleIntroduction_';
                tokenKey = 'CourseModuleToken';
            }
            ctrl.hideIntroduction = shouldHideIntroduction();
        }

        function shouldHideIntroduction() {
            if (typeof (Storage)) {
                var toggleIntroduction = localStorage.getItem(introductionToggleKey + ctrl.data[tokenKey]);
                if (toggleIntroduction && toggleIntroduction.length) {
                    return (toggleIntroduction.toLocaleLowerCase() === 'true');
                }
            }
            return false;
        }

        function toggleIntroduction($event) {
            if ($event.target.nodeName === 'VIDEO') {
                return;
            }
            
            ctrl.hideIntroduction = !ctrl.hideIntroduction;
            if (typeof (Storage)) {
                localStorage.setItem(introductionToggleKey + ctrl.data[tokenKey], ctrl.hideIntroduction);
            }
        }
    }
})();