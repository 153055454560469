(function () {
    'use strict';

    angular.module('App.Catalog')
        .component('catalogItemDetails', {
            template: require('./CatalogItemDetailsComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$stateParams', 'Page', 'QUAGGA_BARCODE_TYPE', "TranslationService", "ToastFactory", CatalogItemDetailsController],
            bindings: {
                item: '<',
                itemCategories: '<',
                settings: '<',
                breadcrumbs: '<',
                category: '<',
                variants: '<',
                accountModule: '<',
                nextToken: '<',
                prevToken: '<'
            }
        });

    function CatalogItemDetailsController($stateParams, Page, QUAGGA_BARCODE_TYPE, TranslationService, ToastFactory) {
        var ctrl = this;

        ctrl.QUAGGA_BARCODE_TYPE = QUAGGA_BARCODE_TYPE;
        ctrl.$onInit = init;
        ctrl.setCurrentImage = setCurrentImage;
        ctrl.goToCategory = goToCategory;
        ctrl.goToCatalog = goToCatalog;
        ctrl.goToVariant = goToVariant;
        ctrl.goToItem = goToItem;
        ctrl.toggleTranslation = toggleTranslation;

        function init() {
            ctrl.showOriginal = false;

            setCurrentTranslationState();

            if (ctrl.item.MainMedia) {
                ctrl.item.AdditionalMedias.unshift(ctrl.item.MainMedia);
            }
            if (ctrl.item.AdditionalMedias.length) {
                _.head(ctrl.item.AdditionalMedias).isCurrent = true;
                ctrl.selectedIndex = ctrl.item.AdditionalMedias.findIndex(media => media.isCurrent);
            }

            ctrl.item.FilteredCustomFields = ctrl.item.CustomFields.filter(function (item) {
                return item.DataListCustomFieldTypeId !== 7 && item.Values[0].DataListItemId == ctrl.item.DataListItemId;
            });

            ctrl.item.CustomFields.forEach(function (field) {
                if (field.DataListCustomFieldTypeId === 5) {
                    field.MediaValues = [];

                    field.Values.forEach(function (value) {
                        if (value.Media) {
                            field.MediaValues.push(value.Media);
                        }
                    });

                    return;
                }

                if (!field.TranslatableEntity) {
                    return;
                }

                field._currentCulture = getCurrentCulture(field.TranslatableEntity);
                field.Values = field.Values.map(value => ({
                    ...value,
                    _currentCulture: value.TranslatableEntity && getCurrentCulture(value.TranslatableEntity)
                }));
            });

            ctrl.variants.forEach(variant => {
                if (!variant.TranslatableEntity) {
                    return;
                }

                variant._currentCulture = getCurrentCulture(variant.TranslatableEntity);
                variant.Values = variant.Values.map(value => ({
                    ...value,
                    _currentCulture: value.TranslatableEntity && getCurrentCulture(value.TranslatableEntity)
                }));
            });

            ctrl.item.Barcode = ctrl.item.CustomFields.find(function (item) {
                return item.DataListCustomFieldTypeId === 7;
            });


            ctrl.item._currentCulture = getCurrentCulture(ctrl.item.TranslatableEntity);
            ctrl.breadcrumbs = ctrl.breadcrumbs.map(breadcrumb => ({
                ...breadcrumb,
                _currentCulture: getCurrentCulture(breadcrumb.TranslatableEntity)
            }));

            ctrl.itemCategories = ctrl.itemCategories.map(category => setCategoryCulture(category));
        }

        function setCategoryCulture(category) {
            if (category.TranslatableEntity) {
                category._currentCulture = getCurrentCulture(category.TranslatableEntity);
            }

            return category;
        }

        function setCurrentTranslationState() {
            const showTranslated = (translatableEntity) => translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale;
            ctrl.showOriginal = true;

            if (
                isTranslatedAllowed(ctrl.item.TranslatableEntity) ||
                ctrl.itemCategories?.length && ctrl.itemCategories.some(item => isTranslatedAllowed(item.TranslatableEntity)) ||
                ctrl.variants?.length && ctrl.variants.some(item =>
                    isTranslatedAllowed(item.TranslatableEntity) && item.Values.some(i => isTranslatedAllowed(i.TranslatableEntity))) ||
                ctrl.item.CustomFields?.length && ctrl.item.CustomFields.some(item =>
                    isTranslatedAllowed(item.TranslatableEntity) && item.Values.some(i => isTranslatedAllowed(i.TranslatableEntity)))
                ) {
                if (ctrl.item.TranslatableEntity) {
                    ctrl.showOriginal = !showTranslated(ctrl.item.TranslatableEntity);
                }

                if (ctrl.itemCategories?.length) {
                    ctrl.showOriginal = ctrl.showOriginal && !ctrl.itemCategories.every(item => showTranslated(item.TranslatableEntity));
                }

                if (ctrl.variants?.length) {
                    ctrl.showOriginal = ctrl.showOriginal && !ctrl.variants.every(item =>
                        showTranslated(item.TranslatableEntity) && !item.Values.every(value => showTranslated(value.TranslatableEntity)));
                }

                if (ctrl.item.CustomFields?.length) {
                    ctrl.showOriginal = ctrl.showOriginal &&
                        !ctrl.item.CustomFields.every(item =>
                            showTranslated(item.TranslatableEntity) && !item.Values.every(value => showTranslated(value.TranslatableEntity)));
                }

                ctrl.translationAllowed = true;
            }
        }

        function isTranslatedAllowed(translatableEntity) {
            return translatableEntity.AllowAutoTranslation ||
                (!translatableEntity.AllowAutoTranslation && translatableEntity.LocalizedAlternativeLocale)
        }

        function getCurrentCulture(translatableEntity) {
            return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale;
        }

        function setCurrentImage(img) {
            _.each(ctrl.item.AdditionalMedias, function (item) {
                item.isCurrent = false;
            });
            img.isCurrent = true;
            ctrl.selectedIndex = ctrl.item.AdditionalMedias.findIndex(el => el.isCurrent);
        }

        function goToCategory(category) {
            Page.stateGo('catalogCategory', $stateParams.token, category.CatalogCategoryToken, Page.getPageTransition());
        }

        function goToCatalog() {
            Page.stateGo(
                'catalog',
                ctrl.accountModule.AccountModuleToken,
                null,
                Page.getPageTransition()
            );
        }

        function goToVariant(item) {
            Page.stateGo(
                'itemDetails',
                $stateParams.token,
                item.DataListItemToken,
                Page.getPageTransition(null),
                _.get(ctrl, 'category.CatalogCategoryToken') || null
            );
        }

        function goToItem(itemToken) {
            Page.stateGo(
                'itemDetails',
                $stateParams.token,
                itemToken,
                Page.getPageTransition(null),
                _.get(ctrl, 'category.CatalogCategoryToken') || null
            );
        }

        function setNewLocales(translatableEntities) {
            ctrl.item.TranslatableEntity = translatableEntities.Item.TranslatableEntity;
            ctrl.item._currentCulture = translatableEntities.Item._currentCulture;
            ctrl.item._currentCultureId = translatableEntities.Item._currentCultureId;

            ctrl.itemCategories = ctrl.itemCategories.map(item => {
                const translatedCategory = translatableEntities.Categories.find(category => category._currentCulture.CatalogCategoryId === item._currentCulture.CatalogCategoryId);

                item.TranslatableEntity = translatedCategory.TranslatableEntity || translatedCategory;
                item._currentCulture = translatedCategory._currentCulture || translatedCategory.TranslatableEntity?.LocalizedLocale || translatedCategory.LocalizedLocale
                item._currentCultureId = translatedCategory._currentCultureId;

                return item;
            });

            ctrl.item.FilteredCustomFields.forEach(field => {
                if (!field.TranslatableEntity) {
                    return;
                }

                const fieldEntity = translatableEntities.Fields.find(translatedField =>
                    translatedField.DataListCustomFieldId === field.DataListCustomFieldId);

                if (fieldEntity) {
                    field.TranslatableEntity = fieldEntity.TranslatableEntity;
                    field._currentCulture = fieldEntity._currentCulture;
                    field._currentCultureId = fieldEntity._currentCultureId;

                    field.Values = field.Values.map(value => {
                        const translatedValue = fieldEntity.Values.find(translatedValue =>
                            translatedValue.DataListCustomFieldValueId === value.DataListCustomFieldValueId);
                        return value.TranslatableEntity ? {
                            ...value,
                            TranslatableEntity: translatedValue.TranslatableEntity,
                            _currentCulture: translatedValue._currentCulture,
                            _currentCultureId: translatedValue._currentCultureId,
                        } : value;
                    });
                }
            });

            ctrl.variants.forEach(variant => {
                if (!variant.TranslatableEntity) {
                    return;
                }

                const variantEntity = translatableEntities.Variants.find(translatedVariant =>
                    translatedVariant.DataListCustomFieldId === variant.DataListCustomFieldId);

                if (variantEntity) {
                    variant.TranslatableEntity = variantEntity.TranslatableEntity;
                    variant._currentCulture = variantEntity._currentCulture;
                    variant._currentCultureId = variantEntity._currentCultureId;

                    variant.Values = variant.Values.map(value => {
                        const translatedValue = variantEntity.Values.find(translatedValue =>
                            translatedValue.DataListCustomFieldValueId === value.DataListCustomFieldValueId);
                        return value.TranslatableEntity ? {
                            ...value,
                            TranslatableEntity: translatedValue.TranslatableEntity,
                            _currentCulture: translatedValue._currentCulture,
                            _currentCultureId: translatedValue._currentCultureId,
                        } : value;
                    });
                }
            });
        }


        function toggleTranslation() {
            if (ctrl.isTranslating) {
                return;
            }

            ctrl.isTranslating = true;
            const translatableEntities = {
                Item: ctrl.item,
                Categories: ctrl.itemCategories,
                Variants: ctrl.variants,
                Fields: ctrl.item.FilteredCustomFields,
            }

            TranslationService
                .switchCatalogTranslatableLocale(translatableEntities, ctrl.item.DataListItemToken)
                .then(newTranslatableEntities => {
                    setNewLocales(newTranslatableEntities);
                    ctrl.showOriginal = !ctrl.showOriginal;
                }).catch(() => ToastFactory.errorTranslated('ERROR.GENERAL'))
                .finally(() => ctrl.isTranslating = false);

        }
    }
})();