(function () {
    'use strict';

    angular.module('App.Social')
        .factory('VotersPopupService', ['PopupWrapperService', function (PopupWrapperService) {

            return {
                open: open
            };

            function open(token, pollOptionId) {
                PopupWrapperService.createDynamic(
                    '<voters-popup token="token" poll-option-id="pollOptionId"></voters-popup>',
                    {
                        token: token,
                        pollOptionId: pollOptionId
                    })
            }
        }]);
})();