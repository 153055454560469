(() => {
    'use strict';

    angular
        .module('App.Social')
        .factory('SocialSortingService', [SocialSortingService]);

    function SocialSortingService() {
        return {
            getOptions: getOptions
        };

        function getOptions(isFeed) {
            if (isFeed) {
                return [
                    {id: 1, icon: 'arrow-up-1-9', title: 'SORTING.NEWEST_FIRST'},
                    {id: 2, icon: 'arrow-down-1-9', title: 'SORTING.OLDEST_FIRST'},
                ];

            } else {
                return [
                    {id: 1, icon: 'bullseye-arrow', title: 'SORTING.MOST_RELEVANT'},
                    {id: 2, icon: 'arrow-up-1-9', title: 'SORTING.NEWEST_FIRST'},
                    {id: 3, icon: 'arrow-down-1-9', title: 'SORTING.OLDEST_FIRST'},
                ];
            }
        }
    }
})();