angular
    .module('App.Survey')
    .component('overallSatisfaction', {
        template: require('./OverallSatisfactionComponent.html'),
        controllerAs: 'ctrl',
        controller: ['SurveyService', OverallSatisfactionComponentController],
        bindings: {
            isLoaded: '<',
            overallResult: '<',
            overallResultValueString: '<',
            rateType: '<',
            showIcon: '<'
        }
    });

function OverallSatisfactionComponentController(SurveyService) {
    const ctrl = this;

    ctrl.getRatingClass = SurveyService.getRatingClass;
    ctrl.getRatingIcon = SurveyService.getRatingIcon;
}