(() => {
    'use strict';

    angular.module('App.Common')
        .directive('lazyImageUrl', ['LazyInitWrapperService', function (LazyInitWrapperService) {
            return {
                restrict: 'A',
                scope: {
                    lazyImageUrl: '@',
                    isBackground: '<',
                    resizeWidth: '<'
                },
                link: (scope, element) => {
                    scope.lazyImageUrl?.length && LazyInitWrapperService.observe(element[0], () => {
                        if (!scope.isBackground) {
                            const img = new Image();
                            img.src = scope.lazyImageUrl;
                            img.style.width = scope.resizeWidth + 'px';
                            img.onload = () => {
                                element[0].classList.remove('loading');
                                element[0].classList.add('loaded');
                            }
                            element[0].appendChild(img);
                            element[0].classList.add('loading');
                        } else {
                            element[0].style.cssText += `background-image: url("${scope.lazyImageUrl}"); background-size: cover;`
                            element[0].style.width = scope.resizeWidth + 'px';
                        }
                    })
                }
            }
        }])
})();