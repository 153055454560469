(() => {
    'use strict';

    angular.module('App.Training')
        .component('trainingVoluntaryCategoryItem', {
            template: require('./TrainingVoluntaryCategoryItemComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', 'Page', 'BasicHelper', TrainingVoluntaryCategoryItemController],
            bindings: {
                category: '<'
            }
        });

    function TrainingVoluntaryCategoryItemController($element, Page, BasicHelper) {
        var ctrl = this;
        
        $element.css({
            'background-image': 'linear-gradient(120deg, ' + BasicHelper.hexToRgba(ctrl.category.Color, 0.6) + ', '
                + BasicHelper.hexToRgba(ctrl.category.Color, 1) + ')'
        });

        $element.click(() => {
            Page.stateGo('trainingCategory', ctrl.category.TrainingCategoryToken, null, Page.getPageTransition());
        })
    }
})();