(() => {
    'use strict';

    angular.module('App')
        .component('surveyPoll', {
            template: require('./SurveyPollComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$timeout', 'Page', '$http', 'toastr', '$translate', '$filter', 'Menu',
                '$stateParams', SurveyPollController],
            bindings: {
                settings: '<',
                polls: '=',
                token: '@'
            }
        })

    function SurveyPollController($rootScope, $timeout, Page, $http, toastr, $translate, $filter, Menu, $stateParams) {
        var ctrl = this,
            loadCounts = 0;

        ctrl.swiper = null;
        ctrl.swiperParams = {
            paginationHide: true
        };
        ctrl.index = 0;
        ctrl.saving = false;

        ctrl.$onInit = init;
        ctrl.listenForEvents = listenForEvents;
        ctrl.selectAnswer = selectAnswer;
        ctrl.vote = vote;
        ctrl.getOptionStyle = getOptionStyle;
        ctrl.back = back;
        ctrl.next = next;

        function init() {
            // Select specific poll
            if (ctrl.token) {
                selectPoll(ctrl.token);
                $rootScope.$broadcast('getSurveyPollToken', ctrl.token);
            }


            // Determine state of poll
            for (var i = 0; i < ctrl.polls.length; i++) {
                var poll = ctrl.polls[i];
                poll.pristine = !poll.IsAnswered;
            }
        }

        // Methods
        function selectPoll(token) {
            if (ctrl.swiper) {
                var index = 0;
                for (var i = 0; i < ctrl.polls.length; i++) {
                    var poll = ctrl.polls[i];
                    if (poll.SurveyToken === token) {
                        index = i;
                    }
                }
                ctrl.swiper.slideTo(index);
            } else {
                loadCounts++;
                if (loadCounts < 50) {
                    $timeout(function () {
                        selectPoll(token);
                    }, 50);
                }
            }
        }

        function getOptions(poll) {
            return poll.Questions[0].AnswerOptions;
        }

        function getOptionStyle(option, poll) {
            if (!poll.pristine) {
                return {
                    width: option.VotePercentage + '%'
                };
            }
            return null;
        }

        function selectAnswer(poll) {
            if (poll.pristine) {
                poll.selected = true;
            }
        }

        function vote(poll) {
            if (poll.pristine && !ctrl.saving) {
                var answer = getAnswer(poll);

                if (answer) {
                    Page.startLoading();
                    ctrl.saving = true;

                    var answers = [{
                        SurveyAnswerOptionId: answer.SurveyAnswerOptionId,
                        AnswerText: answer.Text
                    }];

                    // Send data
                    $http.post('/Survey/AnswerSurvey', {
                        Token: poll.SurveyToken,
                        Answers: answers
                    }).then(function (response) {
                        // Response
                        Page.stopLoading();
                        ctrl.saving = false;

                        poll.pristine = false;
                        poll.newVote = true;

                        // Set votes
                        var data = response.data;
                        var options = getOptions(poll);

                        for (var i = 0; i < options.length; i++) {
                            var newOptions = $filter('filter')(data.Questions[0].AnswerOptions, {
                                SurveyAnswerOptionId: options[i].SurveyAnswerOptionId
                            }, true);

                            if (newOptions && newOptions.length) {
                                options[i].Votes = newOptions[0].Votes;
                                options[i].VotePercentage = newOptions[0].VotePercentage;
                                options[i].OwnAnswer = newOptions[0].OwnAnswer;
                            }
                        }

                        // Remove badge from menu
                        var navBadge = poll.NavigationBadge;
                        Menu.removeLandingBadge(1, navBadge.AccountModuleId, navBadge.ModuleId, navBadge.ModuleItemId);
                        Menu.removeActivity(1, 'survey', $stateParams.token, null);
                    }, function () {
                        // Error
                        Page.stopLoading();
                        ctrl.saving = false;

                        $translate('ERROR.GENERAL').then(function (translation) {
                            toastr.error(translation);
                        });
                    });
                } else {
                    $translate('ERROR.GENERAL').then(function (translation) {
                        toastr.error(translation);
                    });
                }
            }
        }

        function listenForEvents(swiper) {
            ctrl.swiper = swiper;

            swiper.on('slideChange', function () {
                $rootScope.safeApply(function () {
                    ctrl.index = ctrl.swiper.activeIndex;
                });
            });
        }

        function getAnswer(poll) {
            var answer = null;
            var options = getOptions(poll);

            for (var i = 0; i < options.length; i++) {
                if (options[i].picked) {
                    answer = options[i];
                }
            }

            return answer;
        }

        function back() {
            if (ctrl.swiper && ctrl.index > 0) {
                ctrl.swiper.slideTo(ctrl.index - 1);
            }
        }

        function next() {
            if (ctrl.swiper && ctrl.index < (ctrl.polls.length - 1)) {
                ctrl.swiper.slideTo(ctrl.index + 1);
            }
        }
    }
})();