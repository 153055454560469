(function () {
    'use strict';
    var app = angular.module('App.Filters');

    app.filter('htmlFormatText', [
        function () {
            return function (input) {
                var lineBreaksAdded = String(input).replace(/(?:\r\n|\r|\n)/g, '<br />');

                return Autolinker.link(lineBreaksAdded, {
                    stripPrefix: false
                });
            }
        }
    ]);
})();