(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('trainingScores', {
            template: require('./TrainingScoresComponent.html'),
            controllerAs: 'ctrl',
            bindings: {
                performanceScore: '<',
                performanceRange: '<',
                performanceRangeNumber: '<',
                totalItems: '<',
                completedItems: '<'
            }
        });
})();