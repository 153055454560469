(function () {
    'use strict';
    angular
        .module('App.Filters')
        .filter('WrapVideo', [function () {
            return function (input) {
                try {
                    var elements = $(input).find('iframe, video');
                    if (elements.length) {
                        elements.each(function () {
                            $(this).wrap('<p class="video-wrapper"></p>')
                        })
                        return $('<div/>').append(elements.prevObject).html();
                    }
                    return input
                } catch (e) {
                    return input
                }
            }
        }
        ]);
})();