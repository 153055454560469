(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('trainingOverview', {
            template: require('./TrainingOverviewComponent.html'),
            controllerAs: 'ctrl',
            controller: ['TrainingManagerTabService', 'PopupWrapperService', TrainingOverviewController]
        });

    function TrainingOverviewController(TrainingManagerTabService, PopupWrapperService) {
        var ctrl = this,
            popup;

        ctrl.$onInit = function () {
            ctrl.popupId = 'TrainingOverviewPopup';
            TrainingManagerTabService.setTrainingOverviewController(ctrl);
        };

        ctrl.openPopup = function (data) {
            popup = PopupWrapperService.getPopup(ctrl.popupId);
            popup.open();
            ctrl.isOpened = true;
            _.assign(ctrl, data);
            mergeScormDataWithModules();
        };

        ctrl.close = function () {
            ctrl.isOpened = false;
            popup && popup.close()
        }

        ctrl.onRelaunch = function (data) {
            _.assign(ctrl, {...data.Stats, ScormData: data.ScormData});
            mergeScormDataWithModules();
        }

        function mergeScormDataWithModules() {
            // Map ScormCloudModuleEnabled with module properties
            if (ctrl.Modules && ctrl.ScormData) {
                ctrl.Modules = ctrl.Modules
                    .map(module => ({
                        ...module,
                        ScormCloudModuleEnabled: ctrl.ScormData.find(m => module.CourseModuleId === m.CourseModuleId).ScormCloudModuleEnabled
                    }))
            }
        }

    }
})();
