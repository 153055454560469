(() => {
    'use strict';

    angular
        .module('App.Social')
        .component('socialPoll', {
            template: require('./SocialPollComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', '$element', 'ToastFactory', 'ConfirmPopupService', 'SocialPollService',
                'VotersPopupService', SocialPollController],
            bindings: {
                isEdit: '<',
                isCreate: '<',
                isSecondary: '<',
                isDisabled: '<',
                socialPostToken: '<',
                pollId: '=?',
                pollTitle: '=?',
                pollOptions: '=?',
                pollDeadline: '=?',
                allowMultipleAnswers: '=?',
                usersCanAddPollOptions: '=?',
                totalVotesCount: '<',
                votedByUser: '<',
                votersCount: '<',
            }
        });

    function SocialPollController($timeout, $element, ToastFactory, ConfirmPopupService, SocialPollService,
                                  VotersPopupService) {
        var ctrl = this;

        ctrl.showPoll = !ctrl.isCreate;

        ctrl.$onInit = init;
        ctrl.initPollCreation = initPollCreation;
        ctrl.saveTitle = focusLastInput;
        ctrl.addOption = addOption;
        ctrl.removeOption = removeOption;
        ctrl.removePoll = removePoll;
        ctrl.showDateInput = showDateInput;
        ctrl.toggleVote = toggleVote;
        ctrl.showVoters = showVoters;
        ctrl.addUserOption = addUserOption;
        ctrl.pollUntilDeadlineTime = pollUntilDeadlineTime;
        ctrl.showButton = showButton;
        ctrl.getTranslatedLocale = getTranslatedLocale;

        function init() {
            if (ctrl.isEdit) {
                ctrl.showPoll = !!ctrl.socialPostToken && (ctrl.pollTitle && ctrl.pollOptions);
                ctrl.dateInputVisible = !!ctrl.pollDeadline;
                if (ctrl.dateInputVisible) {
                    ctrl.pollDeadline = moment(ctrl.pollDeadline).toDate();
                }
            }
            if (!ctrl.isCreate) {
                ctrl.deadlineEnds = moment(ctrl.pollDeadline).diff(moment()) <= 0;
            }
        }

        function initPollCreation() {
            ctrl.pollOptions = ctrl.pollOptions || getInitOptions();
            ctrl.showPoll = true;
            if (ctrl.isEdit) {
                ctrl.pollId = 0;
            }

            $timeout(function () {
                $element.find('.create-poll header input').focus();
            });
        }

        function showButton(option, key) {
            const text = ctrl.isCreate ? option.Text : getTranslatedLocale(option.TranslatableEntity, 'Text');
            return ctrl.pollOptions?.length !== 1 &&
                (text?.length && key !== ctrl.pollOptions?.length - 1) ||
                (!text?.length && key === ctrl.pollOptions?.length - 1);
        }


        function getTranslatedLocale(translatableEntity, key) {
            return translatableEntity.PreferTranslatedVersion && translatableEntity?.LocalizedAlternativeLocale
            && !ctrl.isCreate ? translatableEntity?.LocalizedAlternativeLocale[key] : translatableEntity?.LocalizedLocale[key]
        }

        function addOption(option) {
            if (!option.Text) {
                ToastFactory.warningTranslated('POLL.ADD_OPTION_WARNING');
            } else {
                ctrl.pollOptions.push({
                    Text: '',
                });
                focusLastInput()
            }
        }

        function removeOption(option) {
            ConfirmPopupService
                .open({
                    message: 'POLL.DELETE_OPTION',
                    yesText: 'YES',
                    noText: 'NO'
                })
                .then(function () {
                    _.remove(ctrl.pollOptions, option)
                })
        }

        function removePoll() {
            ConfirmPopupService
                .open({
                    message: 'POLL.DELETE_POLL',
                    yesText: 'YES',
                    noText: 'NO'
                })
                .then(resetPoll)
        }

        function showDateInput() {
            ctrl.dateInputVisible = true;

            $timeout(function () {
                $element.find('#deadline').focus();
            });
        }

        function focusLastInput() {
            $timeout(function () {
                $element.find('.poll-options .option:last input').focus();
            });
        }

        function toggleVote(option) {
            if (ctrl.deadlineEnds) {
                ToastFactory.errorTranslated('POLL.ALREADY_ENDED');
            } else {
                var previousAnswer = !ctrl.allowMultipleAnswers ?
                    _.first(_.filter(ctrl.pollOptions, {VotedByUser: true})) : null;
                ctrl.isLoading = true;
                SocialPollService
                    .toggleVote(ctrl.socialPostToken, option, previousAnswer)
                    .then(function (poll) {
                        ctrl.isLoading = false;
                        updatePollData(poll)
                    });
            }
        }

        function showVoters(pollOptionId) {
            VotersPopupService.open(ctrl.socialPostToken, pollOptionId);
        }

        function addUserOption(text) {
            ctrl.isLoading = true;
            SocialPollService
                .addUserOption(ctrl.socialPostToken, text)
                .then(function (poll) {
                    ctrl.isLoading = false;
                    ctrl.userOptionText = '';
                    updatePollData(poll)
                })
        }

        function pollUntilDeadlineTime() {
            return SocialPollService.getUntilDeadlineTime(ctrl.pollDeadline);
        }

        function getInitOptions() {
            return [
                {
                    Text: '',
                }
            ]
        }

        function resetPoll() {
            ctrl.pollId = null;
            ctrl.pollTitle = null;
            delete ctrl.pollOptions;
            ctrl.pollDeadline = null;
            ctrl.showPoll = false;
            ctrl.dateInputVisible = false;
        }

        function updatePollData(poll) {
            ctrl.pollTitle = poll.TranslatableEntity._currentLocale?.QuestionText || getTranslatedLocale(poll.TranslatableEntity, 'QuestionText');
            ctrl.pollOptions = poll.PollOptions;
            ctrl.pollDeadline = poll.PollDeadline;
            ctrl.allowMultipleAnswers = poll.AllowMultipleAnswers;
            ctrl.usersCanAddPollOptions = poll.UsersCanAddPollOptions;
            ctrl.totalVotesCount = poll.TotalVotesCount;
            ctrl.votedByUser = poll.VotedByUser;
            ctrl.votersCount = poll.VotersCount;
        }
    }
})();