(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('lessonDisclaimer', {
            controllerAs: 'ctrl',
            template: require('./LessonDisclaimerComponent.html'),
            bindings: {
                lesson: '<'
            }
        })
})();