(function () {
    'use strict';

    angular.module('App.Social')
        .factory('SocialPollService', ['$http', '$filter', function ($http, $filter) {
            return {
                toggleVote: toggleVote,
                addUserOption: addUserOption,
                getUntilDeadlineTime: getUntilDeadlineTime
            }

            function toggleVote(token, option, previousAnswer) {
                var url = !option.VotedByUser ? 'Social/Vote' : 'Social/Unvote';

                if (previousAnswer && previousAnswer.PollOptionId !== option.PollOptionId) {
                    return singleVote(token, option, previousAnswer)
                } else {
                    return $http.post(url, {
                        Token: token,
                        PollOptionId: option.PollOptionId
                    }).then(function (resp) {
                        return resp.data
                    })
                }
            }

            function singleVote(token, option) {
                return $http.post('Social/ChangeVote', {
                    Token: token,
                    PollOptionId: option.PollOptionId
                }).then(function (resp) {
                    return resp.data
                })
            }

            function addUserOption(token, text) {
                return $http.post('Social/AddPollOption', {
                    Token: token,
                    Text: text
                }).then(function (resp) {
                    return resp.data
                })
            }

            function getUntilDeadlineTime(date) {
                var diff = moment.duration(moment(date).diff(moment()), 'milliseconds');

                if (diff.asDays() > 30) {
                    return parseInt(diff.asMonths()) + ' '
                        + (parseInt(diff.asMonths()) === 1 ? $filter('translate')('DATE.MONTH') : $filter('translate')('DATE.MONTHS'));
                }
                if (diff.asDays() > 7) {
                    return diff.weeks() + ' '
                        + (diff.weeks() === 1 ? $filter('translate')('DATE.WEEK') : $filter('translate')('DATE.WEEKS'));
                }
                if (diff.asDays() >= 1) {
                    return diff.days() + ' '
                        + (diff.days() === 1 ? $filter('translate')('DATE.DAY') : $filter('translate')('DATE.DAYS'));
                }
                if (diff.asHours() >= 1) {
                    return diff.hours() + ' '
                        + (diff.hours() === 1 ? $filter('translate')('DATE.HOUR') : $filter('translate')('DATE.HOURS'));
                }
                if (diff.asMinutes() >= 1) {
                    return diff.minutes() + ' '
                        + (diff.minutes() === 1 ? $filter('translate')('DATE.MINUTE') : $filter('translate')('DATE.MINUTES'));
                } else {
                    return $filter('translate')('DATE.LESS_MINUTE');
                }
            }
        }]);
})();