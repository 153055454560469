(() => {

    'use strict';

    angular
        .module('App.Training')
        .component('trainingModulePage', {
            template: require('./TrainingModulePageComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$element', '$filter', 'ToastFactory', 'TrainingService', 'Page',
                TrainingModuleController],
            bindings: {
                module: '<',
                lessons: '<',
                performanceRanges: '<',
                courseToken: '<',
                moduleToken: '<',
            }
        });

    function TrainingModuleController($rootScope, $element, $filter, ToastFactory, TrainingService, Page) {
        const ctrl = this;
        let initCount = 0;

        ctrl.$onInit = init;
        ctrl.onLessonInitDone = onLessonInitDone;
        ctrl.openLesson = openLesson;

        function init() {
            ctrl.completedLessons = ctrl.lessons.filter(lesson => lesson.State === 3).length;
            Page.setCustomCssClass(ctrl.moduleToken);
        }

        function onLessonInitDone() {
            initCount++;
            if (initCount === ctrl.lessons.length) {
                if (ctrl.module.State !== 1) {
                    TrainingService.scrollToTrainingItem($element);
                }
            }
        }

        function openLesson(lesson, index) {
            if (lesson.CanBeTaken) {
                Page.stateGo('trainingLesson', lesson.CourseLessonToken, ctrl.courseToken, Page.getPageTransition(),
                    {
                        lesson,
                        lessonIndex: index,
                        lessons: ctrl.lessons.length
                    });
            } else {
                ToastFactory.warning($filter('translate')('TRAINING.LESSON.NOT_ALLOWED'));
            }
        }
    }
})();