(() => {
    angular
        .module('App.Catalog')
        .config(['$stateProvider', $stateProvider => {
            $stateProvider
                .state('catalog', {
                    parent: 'base',
                    url: '/catalog/:token/:extra',
                    template: require('./Catalog.tpl.html'),
                    params: {
                        backState: null,
                        data: {},
                    },
                    trackPageView: false,
                    resolve: {
                        catalogData: ['base', '$http', '$stateParams', function (base, $http, $stateParams) {
                            if (base) {
                                return $http.post('/Catalog/GetData/' + $stateParams.token);
                            }
                        }]
                    },
                    controller: 'CatalogController as ctrl'
                });
        }
        ])
        .controller('CatalogController', ['$stateParams', 'catalogData', 'Page', 'TrackingService', 'ENTITY_TYPES', 'CatalogSearchService', 
            function ($stateParams, catalogData, Page, TrackingService, ENTITY_TYPES, CatalogSearchService) {

            var ctrl = this;

            Page.setTitle(catalogData.data.PageTitle);

            ctrl.catalogData = catalogData.data;
            
            if (CatalogSearchService.isSearchActive() === false) {
                TrackingService.trackPageView(ENTITY_TYPES.CATALOG.MODULE, catalogData.data.AccountModuleToken, 'Overview');
            }

            if ($stateParams.backState) {
                Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra, null);
            } else {
                Page.showBackButton('home');
            }
        }]);
})();
