(function () {
    'use strict';
    var app = angular.module('App.Elements');

    app.directive('validationMessage', ['$translate', '$timeout',
        function ($translate, $timeout) {
            return {
                restrict: 'E',
                template: require('./ValidationMessage.tpl.html'),
                replace: true,
                scope: {
                    errors: '=',
                    name: '@',
                    replaceBefore: '@',
                    replaceAfter: '@'
                },
                link: function (scope, element, attrs) {
                    // Initialize the Directive
                    initDirective();

                    function initDirective() {
                        scope.message = null;

                        // Watch for changes in the errors list
                        scope.$watch('errors', function () {
                            validateErrors();
                        }, true);

                        // Perform initial validation
                        validateErrors();
                    }

                    // Method to validate errors and set the message
                    function validateErrors() {
                        scope.message = null; // Reset the message

                        if (scope.errors && scope.errors.length) {
                            var error = getError();
                            if (error) {
                                translateErrorMessage(error.Message);
                            }
                        }
                    }

                    // Retrieve the relevant error object based on the name
                    function getError() {
                        if (scope.name) {
                            var fieldName = scope.name.toLowerCase();
                            for (var i = 0; i < scope.errors.length; i++) {
                                if (scope.errors[i].Name.toLowerCase() === fieldName) {
                                    return scope.errors[i];
                                }
                            }
                        }
                        return null;
                    }

                    // Translate the error message and apply text replacements if needed
                    function translateErrorMessage(message) {
                        $translate(message).then(function (translation) {
                            // Perform text replacement if needed
                            if (scope.replaceBefore && scope.replaceAfter) {
                                translation = translation.replace(scope.replaceBefore, scope.replaceAfter);
                            }

                            // Use $timeout to ensure scope updates are applied correctly
                            $timeout(function () {
                                scope.message = translation;
                            });
                        }).catch(function (error) {
                            scope.message = message; // Fallback to original message if translation fails
                        });
                    }
                }
            };
        }
    ]);
})();
