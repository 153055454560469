(() => {
    'use strict';

    angular
        .module('App.Survey')
        .config(['$stateProvider', ($stateProvider) => {
            $stateProvider
                .state('survey', {
                    parent: 'base',
                    url: '/survey/:token/:extra?tab?expired',
                    template: require('./Survey.html'),
                    resolve: {
                        surveyData: ['$http', '$stateParams', ($http, $stateParams) => {
                            if ($stateParams.data && !_.isEmpty($stateParams.data) && $stateParams.data.SurveyToken === $stateParams.extra) {
                                return {
                                    data: $stateParams.data
                                }
                            } else {
                                const url = $stateParams.extra ?
                                    (`/Survey/GetSurvey/${$stateParams.extra}${$stateParams.expired !== undefined ? ('?isExpired=' + $stateParams.expired) : ''}`) :
                                    `/Survey/GetRelevantSurvey/${$stateParams.token}`

                                return $http.get(url);
                            }
                        }]
                    },
                    params: {
                        backState: null,
                        data: {}
                    },
                    reloadOnSearch: false,
                    controller: 'SurveyController'
                })
        }])
        .controller('SurveyController', ['$scope', 'Page', '$stateParams', 'surveyData', 'events', SurveyController]);

    function SurveyController($scope, Page, $stateParams, surveyData, events) {
        $scope.surveyData = surveyData.data;

        if ($stateParams.expired === undefined) {
            $scope.expired = $scope.surveyData?.Survey?.IsExpired;
            Page.stateGo('survey', $stateParams.token, $stateParams.extra, $stateParams.backState, $stateParams.data, false, {expired: $scope.surveyData?.Survey?.IsExpired});
        } else {
            $scope.expired = $stateParams.expired === 'true';
            if ($scope.expired !== $scope.surveyData?.Survey?.IsExpired) {
                $scope.expired = $scope.surveyData?.Survey?.IsExpired;
                Page.stateGo('survey', $stateParams.token, $stateParams.extra, $stateParams.backState, $stateParams.data, false, {expired: $scope.surveyData?.Survey?.IsExpired});
            }
        }

        // Set title
        if (surveyData.data.Survey) {
            if (!$stateParams.extra) {
                Page.stateGo('survey', $stateParams.token, surveyData.data.Survey.SurveyToken, $stateParams.backState, surveyData.data, true, {expired: $scope.surveyData?.Survey?.IsExpired});
                return;
            }

            const surveyTranslatableEntity = surveyData.data.Survey.TranslatableEntity;

            $scope.title = surveyTranslatableEntity.PreferTranslatedVersion ||
            (!surveyTranslatableEntity.PreferTranslatedVersion && surveyTranslatableEntity.LocalizedAlternativeLocale) ?
                surveyTranslatableEntity.LocalizedAlternativeLocale.Title : surveyTranslatableEntity.LocalizedLocale.Title;
        }

        if (surveyData.data.Polls?.length) {
            $scope.title = surveyData.data.PageTitle;
        }

        $scope.menuTitle = surveyData.data.MenuTitle;
        Page.setTitle($scope.title || surveyData.data.PageTitle);

        // Manage the back state
        if ($stateParams.backState !== null && $stateParams.backState != undefined) {
            Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
        } else {
            Page.showBackButton('surveyList', $scope.surveyData.AccountModuleToken, null, null, null, true, {tab: $stateParams.expired ? 'expired' : 'active'});
        }

        // Get settings/data
        $scope.survey = surveyData.data.Survey;
        $scope.settings = surveyData.data.Settings;
        $scope.polls = surveyData.data.Polls;
        $scope.type = surveyData.data.Type;
        $scope.token = surveyData.data.SurveyToken;

        // Listen for events
        $scope.$on(events.DEPARTMENT_CHANGED, () => Page.stateReload());
    }
})();