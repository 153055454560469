(() => {

    'use strict';

    angular.module('App.Training')
        .component('trainingVoluntaryCategory', {
            template: require('./TrainingVoluntaryCategoryPageComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', 'BasicHelper', 'Page', TrainingVoluntaryCategory],
            bindings: {
                relatedCategories: '<',
                childCategories: '<',
                modules: '<',
                color: '<',
                image: '<',
                moduleToken: '<',
            }
        });

    function TrainingVoluntaryCategory($timeout, BasicHelper, Page) {
        var ctrl = this;

        ctrl.$onInit = onInit;
        ctrl.$onDestroy = onDestroy;

        function onInit() {
            Page.setCustomCssClass(ctrl.moduleToken);
        }

        ctrl.headerStyle = {
            backgroundImage: 'linear-gradient(180deg, ' + BasicHelper.hexToRgba(ctrl.color, 0.6) + ', rgba(0,0,0,0) 100%)'
        }

        function onDestroy() {
            Page.removeCustomCssClass(ctrl.moduleToken);
        }
    }
})();