(() => {
    angular.module('App.Survey', []);

    require([
        './SurveyController',
        './SurveyPage/SurveyPageComponent',
        './SurveyPage/SurveyService',

        './SurveyPage/FinishPopup/SurveyFinishPopupComponent',

        './SurveyPage/Survey/SurveyComponent',
        './SurveyPage/SurveySingleDepartmentPageController',
        './SurveyPage/DirectReportsController',

        './SurveyPage/ManagerTab/QuestionStatisticPopup/QuestionStatisticPopupComponent',
        './SurveyPage/ManagerTab/SurveyManagerTabComponent',
        './SurveyPage/ManagerTab/StoreManagerVew/StoreManagerVewComponent',
        './SurveyPage/ManagerTab/MultipleDepartmentView/MultipleDepartmentViewComponent',
        './SurveyPage/ManagerTab/MultipleDepartmentView/DepartmentRowComponent',
        './SurveyPage/ManagerTab/FiltersPopup/SurveyFiltersPopupComponent',
        
        './SurveyPage/CommentsTab/QuestionCommentsPage/QuestionCommentsComponent',
        './SurveyPage/CommentsTab/QuestionCommentsPage/QuestionCommentsController',
        './SurveyPage/CommentsTab/SurveyCommentsTabComponent',
        './SurveyPage/CommentsTab/QuestionCommentsPage/QuestionCommentCardItem/CommentCardComponent',

        './SurveyPage/ManagerTab/Common/NoDataAvailable/NoDataAvailableComponent',
        './SurveyPage/ManagerTab/Common/ParticipationRate/ParticipationRateComponent',
        './SurveyPage/ManagerTab/Common/OverallSatisfaction/OverallSatisfactionComponent',
        './SurveyPage/ManagerTab/Common/SurveyDataCollectionStatus/SurveyDataCollectionStatusComponent',

        './Breakdown/SurveyBreakdownComponent',
        './Poll/SurveyPollComponent',
        './Question/SurveyQuestionComponent',
        './Question/SurveyQuestionFactory',

        './SurveyListController',
        './SurveyList/SurveyListComponent'
    ])
})();