(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('stateIcon', {
            template: require('./StateIconComponent.html'),
            controllerAs: 'ctrl',
            bindings: {
                canBeTaken: '<',
                state: '<',
                passed: '<',
                new: '<',
                expired: '<',
                deadlineStatus: '<'
            }
        })
})();