(() => {
    'use strict';

    angular.module('App.Training')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('trainingCourse', {
                    parent: 'base',
                    url: '/training/course/:token',
                    template: '<training-course-page modules="ctrl.modules" course="ctrl.course"' +
                        ' performance-ranges="ctrl.performanceRanges" module-token="ctrl.moduleToken" ' +
                        'class="view-page training-course-{{ctrl.course.CourseToken}}"></training-course-page>',
                    resolve: {
                        trainingData: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/Training/Course/Get/' + $stateParams.token);
                            }
                        }]
                    },
                    params: {
                        backState: null,
                        data: {}
                    },
                    controller: 'TrainingCourseController as ctrl'
                });
        }])
        .controller('TrainingCourseController', ['$scope', '$stateParams', 'trainingData', 'events', 'Page',
            function ($scope, $stateParams, trainingData, events, Page) {
                var ctrl = this;

                ctrl.modules = trainingData.data.Modules;
                ctrl.course = trainingData.data.Course;
                ctrl.moduleToken = trainingData.data.AccountModuleToken;
                ctrl.performanceRanges = trainingData.data.PerformanceRanges;
                Page.setTitle(trainingData.data.PageTitle);

                if ($stateParams.backState) {
                    Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra);
                } else {
                    if (trainingData.data.PhasesOverviewEnabled && trainingData.data.TrainingPhaseToken) {
                        Page.showBackButton('trainingPhase', trainingData.data.TrainingPhaseToken);
                    } else {
                        Page.showBackButton('training', trainingData.data.AccountModuleToken);
                    }
                }

                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                    Page.stateGoBack('training', trainingData.data.AccountModuleToken);
                });
            }]);
})();