(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('lessonContentComponent', {
            template: require('./LessonContent.html'),
            controllerAs: 'ctrl',
            controller: ['LessonPageService', LessonContentComponentController],
            bindings: {
                lesson: '<',
                answered: '<',
                showAnswers: '<',
                submitData: '=',
                isValid: '='
            }
        });

    function LessonContentComponentController(LessonPageService) {
        const ctrl = this;

        ctrl.onInit = init;
        ctrl.selectAnswer = selectAnswer;

        function init() {
        }

        function selectAnswer(answer, question) {
            ctrl.submitData.Questions = LessonPageService.selectAnswer(answer, question, ctrl.lesson.QuestionList);
            ctrl.isValid = LessonPageService.isAllAnswered(ctrl.submitData.Questions);
        }
    }
})();