(() => {
    'use strict';

    angular
        .module('App.Training')
        .factory('TrainingManagerTabService', ['$q', '$http', '$stateParams', '$filter', 'ConfirmPopupService', TrainingManagerTabService])

    function TrainingManagerTabService($q, $http, $stateParams, $filter, ConfirmPopupService) {
        const PERFORMANCE = {
            LOW: 'low',
            MEDIUM: 'medium',
            HIGH: 'high'
        };
        let performanceValues,
            TrainingOverviewController;

        return {
            setTrainingOverviewController: setTrainingOverviewController,
            openTrainingOverviewPopup: openTrainingOverviewPopup,
            getTrainingTabData: getTrainingTabData,
            getManagerStatistics: getManagerStatistics,
            setPerformanceClass: setPerformanceClass,
            askForRelaunch: askForRelaunch,
            relaunch: relaunch
        }

        function setTrainingOverviewController(ctrl) {
            TrainingOverviewController = ctrl;
        }

        function openTrainingOverviewPopup(user) {
            return $http.post('/Training/Training/GetIndividualUserStatistics', {
                token: $stateParams.token,
                userId: user.UserId
            }).then(resp => {
                const data = {
                    User: user,
                    Settings: performanceValues,
                    ScormData: resp.data.ScormData,
                    ...resp.data.Stats
                }

                TrainingOverviewController.openPopup(data);
            })
        }

        function getTrainingTabData() {
            let courses = [], departments = [], roles = [];

            return $q.all([$http.post('/Training/Training/GetManagerPage', {
                token: $stateParams.token
            }), $http.post('/Training/Training/GetManagerStatistics', {
                token: $stateParams.token
            })]).then(resp => {
                performanceValues = resp[0].data.Settings;
                courses = resp[0].data.Courses;
                if (courses.length > 0 && courses[0].CourseId !== null) {
                    courses.unshift({
                        Title: $filter('translate')('ALL'),
                        CourseId: null
                    });
                }

                departments = resp[0].data.Departments;
                if (departments.length > 0 && departments[0].DepartmentId !== null) {
                    departments.unshift({
                        Name: $filter('translate')('ALL'),
                        DepartmentId: null
                    });
                }

                resp[0].data.Roles.unshift({
                    Name: $filter('translate')('ALL'),
                    RoleId: null
                });

                resp[0].data.Modules.unshift({
                    Title: $filter('translate')('ALL'),
                    CourseModuleId: null
                });

                return {
                    Courses: resp[0].data.Courses,
                    Modules: resp[0].data.Modules,
                    Departments: resp[0].data.Departments,
                    Roles: resp[0].data.Roles,
                    Participants: resp[1].data.Participants,
                    Counts: {
                        HighCount: resp[1].data.HighCount,
                        LowCount: resp[1].data.LowCount,
                        MediumCount: resp[1].data.MediumCount
                    }
                }
            })
        }

        function getManagerStatistics(departmentId, roleId, courseId, moduleId) {
            return $http
                .post('/Training/Training/GetManagerStatistics', {
                    token: $stateParams.token,
                    departmentId,
                    roleId,
                    courseId,
                    moduleId
                })
                .then(resp => ({
                    Participants: resp.data.Participants,
                    Counts: {
                        HighCount: resp.data.HighCount,
                        LowCount: resp.data.LowCount,
                        MediumCount: resp.data.MediumCount
                    }
                }))
        }

        function setPerformanceClass(val) {
            if (val > performanceValues.PerformanceColorRed && val <= performanceValues.PerformanceColorYellow) {
                return PERFORMANCE.LOW
            }
            if (val > performanceValues.PerformanceColorYellow && val <= performanceValues.PerformanceColorGreen) {
                return PERFORMANCE.MEDIUM
            }
            if (val > performanceValues.PerformanceColorGreen) {
                return PERFORMANCE.HIGH
            }
        }

        function askForRelaunch(typeId, name, isScormCloudEnabled) {
            let type = ''

            switch (typeId) {
                case 1: {
                    type = 'course ';
                    break;
                }
                case 2: {
                    type = 'module ';
                    break;
                }
                case 3: {
                    type = 'lesson ';
                    break;
                }
                case 5: {
                    type = 'phase ';
                    break;
                }
            }

            const scormAdditionalMessage = isScormCloudEnabled ? $filter('translate')('TRAINING.RELAUNCH.MESSAGE_SCORM') : '';

            const opts = {
                title: $filter('translate')('TRAINING.RELAUNCH.TITLE'),
                message: $filter('translate')('TRAINING.RELAUNCH.MESSAGE', {
                    type: type,
                    name: name,
                    scorm: scormAdditionalMessage
                }),
                yesText: $filter('translate')('TRAINING.RELAUNCH.RELAUNCH'),
                noText: $filter('translate')('TRAINING.RELAUNCH.CANCEL'),
            };

            return ConfirmPopupService.open(opts);
        }

        function relaunch(itemTypeId, itemId, userId) {
            return $http.post('/Training/Training/Relaunch', {
                token: $stateParams.token,
                userId: userId,
                itemTypeId: itemTypeId,
                itemId: itemId
            })
        }
    }
})();
