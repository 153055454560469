(() => {
    'use strict';

    angular
        .module('App.Survey')
        .component('surveyDataCollectionStatus', {
            template: require('./SurveyDataCollectionStatusComponent.html'),
            controllerAs: 'ctrl',
            controller: ['TimeLocale', SurveyDataCollectionStatusController],
            bindings: {
                managerViewSettings: '<'
            }
        })

    function SurveyDataCollectionStatusController(TimeLocale) {
        const ctrl = this;
        ctrl.$onInit = onInit;

        function onInit() {
            if (ctrl.managerViewSettings.IsDataCollectionInProgress 
                && ctrl.managerViewSettings.DataCollectionEndDate) {
                    
                const now = moment();
                const targetDate = TimeLocale.getMomentDate(ctrl.managerViewSettings.DataCollectionEndDate);
                const duration = moment.duration(targetDate.diff(now)).humanize(true);

                ctrl.collectionEndDate = duration;
                ctrl.collectionEndDateDetailed = targetDate.format('DD MMM YYYY HH:mm');

            } else {
                ctrl.collectionEndDate = null;
                ctrl.collectionEndDateDetailed = null;
            }
        }
    }
})();