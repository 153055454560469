(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('courseItem', {
            template: require('./CourseItemComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$translate', '$timeout', 'TrainingService', 'Page', 'toastr', CourseItemController],
            bindings: {
                course: '<',
                onCourseInit: '<',
                showTranslated: '<',
            }
        })

    function CourseItemController($translate, $timeout, TrainingService, Page, toastr) {
        var ctrl = this;

        ctrl.getStatusClass = TrainingService.getItemStatusClass;
        ctrl.getStatus = TrainingService.getItemStatus;
        ctrl.getDurationString = TrainingService.getDurationString;
        ctrl.openCourse = openCourse;
        ctrl.$onInit = init;

        function init() {
            $timeout(() => {
                ctrl.onCourseInit && ctrl.onCourseInit();
            })
        }

        function openCourse(course) {
            if (course.CanBeTaken) {
                toastr.clear();
                Page.stateGo('trainingCourse', course.CourseToken, null, Page.getPageTransition())
            } else {
                $translate('TRAINING.COURSE.NOT_ALLOWED').then(function (translation) {
                    toastr.warning(translation);
                });
            }
        }

    }
})();