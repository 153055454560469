(function () {
    'use strict';

    angular.module('App.Social')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('socialHashtags', {
                    parent: 'base',
                    url: '/socialhashtags/:token/:extra',
                    template: '<social-hashtags class="view-page" posts="ctrl.posts" posts-data="ctrl.postsData" hashtag="ctrl.hashtag" more-posts-to-load="ctrl.morePostsToLoad"></social-hashtags>',
                    params: {
                        backState: null,
                        data: undefined
                    },
                    resolve: {
                        postsData: ['$http', '$stateParams',
                            function ($http, $stateParams) {
                                return $http.post('/Social/GetPostsWithHashtag', {
                                    token: $stateParams.token,
                                    offset: 0,
                                    limit: 10,
                                    hashtag: $stateParams.extra
                                }).then(function (response) {
                                    return response.data;
                                });
                            }]
                    },
                    controller: 'SocialHashtagsController as ctrl'
                });
        }])
        .controller('SocialHashtagsController', 
            ['$state', '$stateParams', 'Page', 'postsData', SocialHashtagsController]
        );

    function SocialHashtagsController($state, $stateParams, Page, postsData) {
        var ctrl = this, settings = {
            HashtagsEnabled: true
        };

        if ($state.params.backState) {
            Page.showBackButton($state.params.backState.state, $state.params.backState.token, $state.params.backState.extra);
        } else {
            Page.showBackButton('social', $state.params.token);
        }


        Page.setTitle('#' + $stateParams.extra);
        ctrl.posts = postsData.Posts;
        ctrl.postsData = {
            Settings: settings
        };
        ctrl.hashtag = postsData.Hashtag || {};
        ctrl.hashtag.Name = $stateParams.extra;
        ctrl.morePostsToLoad = postsData.MorePosts;
    }
})();