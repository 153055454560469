angular
    .module('App.Survey')
    .component('surveyFiltersPopup', {
        template: require('./SurveyFiltersPopupComponent.html'),
        controllerAs: 'ctrl',
        controller: ['$element', '$timeout', FiltersPopupController],
        bindings: {
            userGroups: '<',
            filtersModel: '<',
            onFiltersApply: '<'
        }
    });

function FiltersPopupController($element, $timeout) {
    const ctrl = this;


    ctrl.onPopupRegistered = onPopupRegistered;
    ctrl.applyFilters = applyFilters;
    ctrl.clearFilters = clearFilters;
    ctrl.close = close;

    function onPopupRegistered(popup) {
        ctrl.filtersModel = {...ctrl.filtersModel} || {};
        ctrl.popup = popup;
        ctrl.popup.open();
    }

    function applyFilters() {
        ctrl.onFiltersApply(ctrl.filtersModel);
        close();
    }

    function clearFilters() {
        ctrl.resetUsergroup && ctrl.resetUsergroup();
        ctrl.onFiltersApply();
        close();
    }

    function close(_) {
        ctrl.popup.remove();
        $element.remove();
    }
}
