(() => {
    angular
        .module('App.Training', [])
        .constant('LESSON_STATE', {
            NOT_STARTED: 1,
            IN_PROGRESS: 2,
            COMPLETED: 3,
        })
        .constant('LESSON_PAGE_VIEW', {
            INTRODUCTION: 0,
            QUESTIONS: 1,
            EVALUATION: 2,
        })
        .constant('TRAINING_VIEW', {
            MY_TRAINING: 1,
            MY_PROGRESS: 2,
            MANAGER: 3,
            VOLUNTARY: 4
        })
        .constant('TRAINING_ROUTE', {
            PARENT: 'training',
            MY_TRAINING: 'trainingMy',
            MY_PROGRESS: 'trainingProgress',
            MANAGER: 'trainingManager',
            VOLUNTARY: 'trainingVoluntary'
        })
        .constant('TRAINING_QUESTION_TYPE', {
            SINGLE_AND_MULTI_SELECT: 1,
            SWIPE_CARD: 2,
            IMAGE_AS_AN_ANSWER: 3,
        });
    require([
        './TrainingRoutes',
        './TrainingController',
        './TrainingService',
        './TrainingComponent',

        './Voluntary/VoluntaryCategoryPage/TrainingVoluntaryCategoryPageController',
        './Voluntary/VoluntaryCategoryPage/TrainingVoluntaryCategoryPageComponent',
        './Voluntary/VoluntaryCategoryItem/TrainingVoluntaryCategoryItemComponent',
        './Voluntary/VoluntaryPage/TrainingVoluntaryPageComponent',
        './Voluntary/VoluntaryPage/TrainingVoluntaryService',

        './ScormCloudCoursePage/ScormCloudCoursePageController',

        './MyTraining/MyTrainingComponent',
        './MyTraining/PhasePage/PhasePageController',
        './MyTraining/PhasePage/PhasePageComponent',
        './MyTraining/CourseItem/CourseItemComponent',

        './MyProgress/MyProgressComponent',
        './MyProgress/Badges/TrainingBadgesComponent',

        './ModulePage/TrainingModulePageController',
        './ModulePage/TrainingModulePageComponent',
        './ModulePage/LessonItem/TrainingLessonItemComponent',

        './ManagerTab/TrainingManagerTabComponent',
        './ManagerTab/TrainingManagerTabService',
        './ManagerTab/FiltersPopup/TrainingFiltersPopupComponent',
        './ManagerTab/FiltersPopup/TrainingFiltersPopupService',
        './ManagerTab/OverviewTable/OverviewTableComponent',
        './ManagerTab/TrainingMaterialSelector/TrainingMaterialSelectorComponent',
        './ManagerTab/TrainingOverview/TrainingOverviewComponent',
        './ManagerTab/UsersTable/UsersTableComponent',

        './LessonPage/LessonContent/LessonContent',

        './LessonPage/LessonEvaluation/LessonEvaluationComponent',
        './LessonPage/LessonQuestions/LessonQuestionsComponent',
        './LessonPage/LessonQuestions/LessonQuestionsService',
        './LessonPage/LessonQuestions/LessonQuestion/LessonQuestionComponent',
        './LessonPage/LessonQuestions/LessonSwipeCard/LessonSwipeCardComponent',
        './LessonPage/LessonQuestions/LessonImageQuestion/LessonImageQuestion',
        './LessonPage/LessonDisclaimer/LessonDisclaimerComponent',
        './LessonPage/LessonTestimonials/LessonTestimonialsComponent',
        './LessonPage/LessonIntroduction/LessonIntroductionComponent',

        './LessonPage/LessonPageController',
        './LessonPage/LessonPageComponent',
        './LessonPage/LessonPageService',
        './LessonPage/LessonPageV2Component',

        './CoursePage/TrainingCoursePageController',
        './CoursePage/TrainingCoursePageComponent',
        './CoursePage/ModuleItem/TrainingModuleItemComponent',

        './Common/BadgesList/BadgesListComponent',
        './Common/BadgesList/Badge/BadgeComponent',
        './Common/BadgePopup/BadgePopupComponent',
        './Common/BadgeTestimonialsPopup/BadgeTestimonialsPopupComponent',
        './Common/Introduction/TrainingIntroductionComponent',
        './Common/LearnMore/TrainingLearnMoreComponent',
        './Common/Scores/TrainingScoresComponent',
        './Common/StateIcon/StateIconComponent',
        './Common/Timer/TimerComponent',
    ])
})();