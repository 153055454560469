(() => {
    'use strict';

    angular
        .module('App')
        .factory('SurveyQuestionFactory', ['CopyLinkService', 'ActionSheetService',
            function (CopyLinkService, ActionSheetService) {
                return {
                    showOptions: showOptions
                };

                function showOptions(event, surveyToken) {
                    const buttons = [];

                    buttons.push({
                        text: 'COPY_LINK',
                        icon: 'link',
                        onClick: function () {
                            copySurveyLink(surveyToken);
                        }
                    });

                    ActionSheetService
                        .create(buttons, event.currentTarget)
                        .show();
                }

                function copySurveyLink(surveyToken) {
                    CopyLinkService.copy(
                        '/Survey/GenerateLink/' + surveyToken,
                        angular.element('.app-link-url')
                    )
                }
            }
        ]);
})();