(() => {
    'use strict'

    angular
        .module('App.Catalog')
        .component('catalogGridItem', {
            template: require('./CatalogGridItemComponent.html'),
            controllerAs: 'ctrl',
            controller: [catalogGridItemController],
            bindings: {
                type: '@',
                item: '<'
            }
        })

    function catalogGridItemController() {
        const ctrl = this;
        
        ctrl.$onInit = init;

        function init() {
            ctrl.media = ctrl.type === 'item' ? ctrl.item.Item.AdditionalValues.Media : ctrl.item.Media;
            if (ctrl.item.TranslatableEntity.AllowAutoTranslation || (!ctrl.item.TranslatableEntity.AllowAutoTranslation && ctrl.item.TranslatableEntity.LocalizedAlternativeLocale)) {
                ctrl.Title = ctrl.item.TranslatableEntity.PreferTranslatedVersion ? ctrl.item.TranslatableEntity.LocalizedAlternativeLocale.Title : ctrl.item.TranslatableEntity.LocalizedLocale.Title;
            } else {
                ctrl.Title = ctrl.item.TranslatableEntity.LocalizedLocale.Title;
            }
        }
    }
})();