(() => {
    'use strict';

    angular
        .module('App.Catalog')
        .component('catalogCategory', {
            template: require('./CatalogCategoryComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$stateParams', 'Page', 'CatalogSearchService', 'ENTITY_TYPES', CatalogCategoryController],
            bindings: {
                settings: '<',
                items: '<',
                searchString: '<',
                category: '<',
                subcategories: '<',
                breadcrumbs: '<',
                accountModule: '<',
                searchPlaceholder: '<',
            }
        });

    function CatalogCategoryController($stateParams, Page, CatalogSearchService, ENTITY_TYPES) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.goToCategory = goToCategory;
        ctrl.goToCatalog = goToCatalog;

        function init() {
            ctrl.itemsSource = _.cloneDeep(ctrl.items);

            ctrl.categoryToken = ctrl.category.CatalogCategoryToken;
            ctrl.moduleToken = ctrl.accountModule.AccountModuleToken;

            ctrl.searchSettings = {
                accountModuleId: ctrl.settings.AccountModuleId,
                entityType: ENTITY_TYPES.CATALOG.CATEGORY,
                entityId: ctrl.categoryToken,
                viewName: 'CategoryView'
            };
        }

        function goToCategory(category) {
            Page.stateGo(
                'catalogCategory',
                $stateParams.token,
                category.CatalogCategoryToken,
                Page.getPageTransition()
            );
        }

        function goToCatalog() {
            Page.stateGo(
                'catalog',
                ctrl.accountModule.AccountModuleToken,
                null,
                Page.getPageTransition()
            );
        }

        function destroy() {
            if (ctrl.moduleToken !== $stateParams.token) {
                CatalogSearchService.cleanSearchState();
            }
        }
    }
})();