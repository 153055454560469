(() => {
    'use strict';

    angular
        .module('App.GlobalSearch')
        .factory('GlobalSearchService', ['$q', '$http','GLOBAL_SEARCH_MODULE_LABEL', GlobalSearchService]);

    function GlobalSearchService($q, $http, GLOBAL_SEARCH_MODULE_LABEL) {
        let searchState = {}, lastSearchCanceler;

        return {
            getSearchSettings: getSearchSettings,
            search: search,
            cancelSearch: cancelSearch,
            setSearchState: setSearchState,
            getSearchState: getSearchState,
            cleanSearchState: cleanSearchState,
            getFilterByModuleOptions: getFilterByModuleOptions 
        }

        function getSearchSettings() {
            return $http.get('/SearchV2/GetSearchPage')
        }

        function search(model) {
            const canceller = $q.defer(),
                searchModel = {
                    ...model,
                    startDate: model.startDate ? moment(model.startDate).format('YYYY-MM-DD[T]HH:mm:ss') : null,
                    endDate: model.endDate ? moment(model.endDate).format('YYYY-MM-DD[T]HH:mm:ss') : null
                };

            delete searchModel.searchResults;
            delete searchModel.scrollTop;

            if (lastSearchCanceler) {
                lastSearchCanceler.resolve();
                lastSearchCanceler = undefined;
            }

            const promise =
                $http
                    .post('/SearchV2/Search/', searchModel, {timeout: canceller.promise})
                    .then(resp => {
                        lastSearchCanceler = undefined;
                        return resp.data
                    });

            lastSearchCanceler = canceller;

            return promise;
        }

        function cancelSearch() {
            if (lastSearchCanceler) {
                lastSearchCanceler.resolve();
                lastSearchCanceler = undefined;
            }
        }

        function setSearchState(state) {
            searchState = state;
        }

        function getSearchState() {
            return searchState
        }

        function cleanSearchState() {
            searchState = {};
        }
        
        function getFilterByModuleOptions(modules) {
            return modules?.length ? modules.map(option => ({
                id: option,
                title: GLOBAL_SEARCH_MODULE_LABEL[option]
            })) : [];
        }
    }
})();