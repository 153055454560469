(function () {
    'use strict';
    angular.module('App.Filters')
        .filter('numberFormat', [
            function () {
                return function (input) {
                    if (input && Intl) {
                        return new Intl.NumberFormat().format(input);
                    } else {
                        return input
                    }
                };
            }
        ]);
})();