angular
    .module('App.Survey')
    .config(['$stateProvider', ($stateProvider) => {
        $stateProvider
            .state('questionComments', {
                parent: 'base',
                url: '/survey-question-comments/:token/:extra/:departmentToken?isDirectReports?expired',
                template: `
                        <question-comments-component
                            survey-question-token="ctrl.surveyQuestionToken"
                            survey-instance-token="ctrl.surveyInstanceToken"
                            department-token="ctrl.departmentToken"
                            filters="ctrl.filters"
                            is-direct-reports="ctrl.isDirectReports"
                            class="view-page"
                        ></question-comments-component>`,
                params: {
                    backState: null,
                    data: {},
                    filters: null,
                    isDirectReports: null
                },
                controller: 'QuestionCommentsController as ctrl',
            })
    }])
    .controller('QuestionCommentsController', ['$rootScope', 'Page', '$stateParams', QuestionCommentsController]);


function QuestionCommentsController($rootScope, Page, $stateParams) {
    const ctrl = this;

    ctrl.departmentToken = $stateParams.departmentToken?.length ? $stateParams.departmentToken : null;
    ctrl.isDirectReports = $stateParams.isDirectReports === 'true';
    ctrl.surveyQuestionToken = $stateParams.token;
    ctrl.surveyInstanceToken = $stateParams.extra;
    ctrl.filters = $stateParams.filters

    if ($stateParams.backState !== null) {
        Page.showBackButton($stateParams.backState?.state, $stateParams.backState?.token, $stateParams.backState?.extra, null, null, true, $stateParams.backState.restParams);
    } else {
        Page.showBackButton('home');
    }

    Page.setTitle('SURVEY.COMMENTS');
}