(() => {

    angular
        .module('App.Training')
        .config(['$stateProvider', ($stateProvider) => {
            $stateProvider
                .state('training', {
                    parent: 'base',
                    url: '/training/:token/',
                    params: {
                        data: {},
                        backState: null,
                    },
                    template: '<ui-view></ui-view>',
                    resolve: {
                        trainingSettings: ['$state', '$http', '$stateParams', ($state, $http, $stateParams) => {
                            if (!$stateParams.data?.PageTitle) {
                                return $http
                                    .get('/Training/Training/GetSettings/' + $stateParams.token)
                                    .then(resp => resp.data);
                            } else {
                                return $stateParams.data
                            }
                        }]
                    },
                    controller: ['$state', '$stateParams', 'trainingSettings', 'Page', 'TRAINING_ROUTE', ($state, $stateParams, trainingSettings, Page, TRAINING_ROUTE) => {
                        if ($state.current.name === TRAINING_ROUTE.PARENT) {
                            if (trainingSettings.IsMandatoryAllowed) {
                                go(TRAINING_ROUTE.MY_TRAINING)
                            } else if (!trainingSettings.IsMandatoryAllowed && trainingSettings.IsVoluntaryAllowed) {
                                go(TRAINING_ROUTE.VOLUNTARY)
                            } else {
                                go(TRAINING_ROUTE.MY_PROGRESS)
                            }
                        }

                        function go(route) {
                            Page.stateGo(route, $stateParams.token, null, Page.getPageTransition(), trainingSettings)
                        }
                    }]
                })
                .state('trainingMy', createStateConfig('index'))
                .state('trainingProgress', createStateConfig('progress'))
                .state('trainingManager', createStateConfig('manager'))
                .state('trainingVoluntary', createStateConfig('voluntary'));
        }])

    function createStateConfig(url) {
        return {
            parent: 'training',
            url: url,
            params: {
                data: {},
                backState: null,
            },
            template: `<training training-settings="ctrl.trainingSettings" state="ctrl.state" class="view-page"></training>`,
            controller: 'TrainingController as ctrl'
        }
    }
})();