(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('trainingCoursePage', {
            template: require('./TrainingCoursePageComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', 'TrainingService', 'Page', TrainingCoursePageController],
            bindings: {
                course: '<',
                modules: '<',
                performanceRanges: '<',
                moduleToken: '<',
            }
        });

    function TrainingCoursePageController($element, TrainingService, Page) {
        var ctrl = this, initCount = 0;

        ctrl.onInitDone = onInitDone;
        ctrl.completedModules = _.filter(ctrl.modules, {State: 3}).length;
        ctrl.$onInit = onInit;
        ctrl.$onDestroy = onDestroy;

        function onInit() {
            Page.setCustomCssClass(ctrl.moduleToken);
        }

        function onInitDone() {
            initCount++;
            if (initCount === ctrl.modules.length && ctrl.course.State !== 1) {
                TrainingService.scrollToTrainingItem($element);
            }
        }

        function onDestroy() {
            Page.removeCustomCssClass(ctrl.moduleToken);
        }
    }
})();