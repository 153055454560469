(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('badgesList', {
            template: require('./BadgesListComponent.html'),
            controllerAs: 'ctrl',
            bindings: {
                badges: '<',
                onBadgeClick: '<'
            }
        });
})();