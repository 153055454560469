(() => {
    'use strict';

    angular
        .module('App.Social')
        .component('socialGroup', {
            template: require('./SocialGroupComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['SocialGroupService', SocialGroupController],
            bindings: {
                group: '<',
                settings: '<',
                moduleToken: '<'
            }
        });

    function SocialGroupController(SocialGroupService) {
        const ctrl = this;

        ctrl.onToggleFollow = onToggleFollow;

        function onToggleFollow(e) {
            const ToggleFollow = ctrl.group.Following ?
                SocialGroupService.unfollowSocialGroup :
                SocialGroupService.followSocialGroup;

            e.stopPropagation();
            e.preventDefault();

            ctrl.group.Following = !ctrl.group.Following;

            ToggleFollow(ctrl.moduleToken, ctrl.group.SocialGroupId)
                .catch(er => {
                    ctrl.group.Following = !ctrl.group.Following;
                });
        }
    }
})();