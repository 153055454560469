(() => {
    'use strict';

    angular
        .module('App.GlobalSearch')
        .component('filterByModuleComponent', {
            template: require('./FilterByModuleComponent.html'),
            controller: ['ActionSheetCustomService', FilterByModuleController],
            controllerAs: 'ctrl',
            bindings: {
                onModuleFilterSelected: '<',
                options: '=',
                reset: '=?',
                onBadgeRemove: '=?'
            }
        });

    function FilterByModuleController(ActionSheetCustomService) {
        const ctrl = this;

        ctrl.openFilters = openFilters;
        ctrl.isSelected = isSelected;
        ctrl.onBadgeClick = onBadgeClick;

        function openFilters() {
            ActionSheetCustomService.create(
                `<filter-by-module-popup-component
                    on-module-filter-selected="onModuleFilterSelected"
                    options="options"
                    on-clear-filter="onClearFilter"
                ></filter-by-module-popup-component>`,
                {
                    onModuleFilterSelected: onSubmit,
                    options: ctrl.options,
                    onClearFilter
                }, 'filter-by-module-component');
        }

        function onBadgeClick(item) {
            if (!item.selected) {
                const options = ctrl.options.map((option) => {
                    return option.id === item.id ? { ...option, selected: false } : option;
                })
                ctrl.onBadgeRemove(options);
            }
        } 
        
        function onClearFilter() {
            ctrl.reset();
        }

        function onSubmit(options) {
             ctrl.onModuleFilterSelected(options);
        }
        
        function isSelected(item) {
            return item.selected;
        }
    }
})();