(() => {
    'use strict';

    angular
        .module('App.Social')
        .component('socialHashtags', {
            template: require('./SocialHashtagsComponent.tpl.html'),
            controllerAs: 'ctrl',
            bindings: {
                posts: '<',
                postsData: '<',
                hashtag: '<',
                morePostsToLoad: '<'
            }
        });
})();