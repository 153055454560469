(() => {
    'use strict';

    angular
        .module(
            'App.GlobalSearch',
            ['App.Common', 'App.Basics']
        );

    require([
        './GlobalSearchPageComponent',
        './GlobalSearchService',
        './GlobalSearchPageController',
        './FilterByModule/FilterByModuleComponent',
        './FilterByModule/FilterByModulePopup/FilterByModulePopupComponent',
    ]);
})();