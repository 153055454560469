(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('lessonQuestions', {
            controllerAs: 'ctrl',
            template: require('./LessonQuestionsComponent.html'),
            controller: ['$animate', '$scope', '$timeout', '$filter', 'TRAINING_QUESTION_TYPE', 'Page', 'LessonPageService',
                'ResponsiveService', 'LessonQuestionsService', LessonQuestionsController],
            bindings: {
                lesson: '<',
                onSubmitAnswers: '<',
                questionId: '<',
                isLoading: '<'
            }
        })

    function LessonQuestionsController($animate, $scope, $timeout, $filter, TRAINING_QUESTION_TYPE, Page,
                                       LessonPageService, ResponsiveService, LessonQuestionsService) {
        const ctrl = this;

        ctrl.isNext = true;
        ctrl.TRAINING_QUESTION_TYPE = TRAINING_QUESTION_TYPE;
        ctrl.isDesktop = ResponsiveService.isDesktop();

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.selectAnswer = selectAnswer;
        ctrl.resetAnswer = resetAnswer;
        ctrl.changeQuestion = changeQuestion;
        ctrl.submitAnswers = submitAnswers;
        ctrl.submitOnTimeOver = submitOnTimeOver;

        function init() {
            window.scrollTo(0, 0);

            ctrl.showAnswers = !!ctrl.questionId;
            ctrl.currentQuestionIndex = getCurrentQuestionIndex();
            ctrl.currentQuestion = ctrl.lesson.QuestionList[ctrl.currentQuestionIndex];
            ctrl.answerMessage = LessonQuestionsService.getAnswerMessage(ctrl.lesson.QuestionList[ctrl.currentQuestionIndex]);

            $timeout(() => {
                document.querySelector('lesson-questions.hidden').classList.remove('hidden');
                document.querySelector('.base-view').classList
                    .add('module-training-lesson-questions', ctrl.showAnswers && 'show-answers');
                document.querySelector(`.content-wrapper .question-wrapper[data-index='${ctrl.currentQuestionIndex}']`)
                    .classList.add('current');
                

                if (!ctrl.showAnswers) {
                    ctrl.cardsInStack = LessonQuestionsService.setStackVisibility(0, ctrl.lesson.QuestionList);
                }
            });

            setTitle();
        }

        function getCurrentQuestionIndex() {
            return ctrl.questionId ?
                ctrl.lesson.QuestionList.findIndex(question => question.CourseQuestionId === ctrl.questionId) : 0;
        }

        function changeQuestion(isNext, noAnimation) {
            if (ctrl.changeDisabled) {
                return false
            }
            const container = document.querySelector('.content-wrapper'),
                nextIndex = isNext ? ctrl.currentQuestionIndex + 1 : ctrl.currentQuestionIndex - 1,
                currentEl = document.querySelector('.content-wrapper .question-wrapper.current'),
                nextEl = LessonQuestionsService.getQuestionByIndex(nextIndex);

            window.scrollTo(0, 0);

            ctrl.changeDisabled = true
            ctrl.currentQuestionIndex = nextIndex;
            ctrl.currentQuestion = ctrl.lesson.QuestionList[nextIndex];

            if (noAnimation) {
                currentEl.classList.remove('current');
                nextEl.classList.add('current');
                ctrl.cardsInStack = LessonQuestionsService.setStackVisibility(ctrl.currentQuestionIndex, ctrl.lesson.QuestionList);
                ctrl.changeDisabled = false;
            } else {
                setupAnimationListeners();
                $animate.removeClass(currentEl, 'current');
                $animate.addClass(nextEl, 'current');
            }

            ctrl.isNext = isNext;
            ctrl.isValid = LessonPageService.isAllAnswered(ctrl.submitData);
            setTitle();

            function setupAnimationListeners() {
                $animate.on('removeClass', container, (el, phase) => {
                    if (phase === 'start') {
                        if (!isNext) {
                            if (el[0].classList.contains('in-stack') && ctrl.currentQuestion.QuestionType === TRAINING_QUESTION_TYPE.SWIPE_CARD) {
                                el[0].classList.remove('current-remove')
                            }
                            $scope.$apply(() => {
                                ctrl.cardsInStack = LessonQuestionsService.setStackVisibility(ctrl.currentQuestionIndex, ctrl.lesson.QuestionList);
                            })
                        }
                    }
                    if (phase === 'close') {
                        if (isNext) {
                            $scope.$apply(() => {
                                ctrl.cardsInStack = LessonQuestionsService.setStackVisibility(ctrl.currentQuestionIndex, ctrl.lesson.QuestionList);
                            })
                        }
                        $animate.off('removeClass', container);
                    }
                });
                $animate.on('addClass', container, (el, phase) => {
                    if (isNext && phase === 'start') {
                        if (el[0].classList.contains('in-stack')) {
                            el[0].classList.remove('current-add')
                        }
                    }
                    if (phase === 'close') {
                        $scope.$apply(() => ctrl.changeDisabled = false);
                        $animate.off('addClass', container);
                    }
                });
            }
        }

        function submitAnswers() {
            ctrl.onSubmitAnswers(ctrl.submitData);
        }

        function submitOnTimeOver() {
            ctrl.submitData = ctrl.submitData?.filter(question => question.AnswerOptions?.length > 0);
            submitAnswers();
        }

        function setTitle() {
            LessonPageService.removeTitleHelp();
            const label = $filter('translate')('TRAINING.QUESTION.QUESTION_TITLE') + ' ' +
                `${ctrl.currentQuestionIndex + 1}/${ctrl.lesson.QuestionList.length}`;

            Page.setTitle(ctrl.lesson.Title, label);
            LessonPageService.setTitleHelp(ctrl.currentQuestion);
        }

        function selectAnswer(answer, onAnswerSelect) {
            if (ctrl.currentQuestion.QuestionType === TRAINING_QUESTION_TYPE.SWIPE_CARD) {
                //save link to currentQuestion, ctrl.currentQuestion will change during timeout
                const currentQuestion = ctrl.currentQuestion;
                if (ctrl.currentQuestionIndex !== (ctrl.lesson.QuestionList.length - 1)) {
                    changeQuestion(true, true);
                }
                $timeout(() => {
                    select(currentQuestion);
                    onAnswerSelect && onAnswerSelect();
                }, 200);
            } else {
                select(ctrl.currentQuestion);
                onAnswerSelect && onAnswerSelect();
            }

            function select(currentQuestion) {
                ctrl.submitData = LessonPageService.selectAnswer(answer, currentQuestion, ctrl.lesson.QuestionList);
                ctrl.isValid = LessonPageService.isAllAnswered(ctrl.submitData);
            }
        }

        function resetAnswer() {
            ctrl.submitData = LessonPageService.resetAnswer(ctrl.currentQuestion, ctrl.lesson.QuestionList);
            ctrl.isValid = LessonPageService.isAllAnswered(ctrl.submitData);
        }


        function destroy() {
            LessonPageService.removeTitleHelp();
            document.querySelector('lesson-questions').classList.add('hidden');
            document.querySelector('.base-view').classList
                .remove('module-training-lesson-questions', 'show-answers');
        }
    }
})();