(() => {
    angular
        .module('App.Survey')
        .component('surveyFinishPopup', {
            template: require('./SurveyFinishPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', SurveyFinishPopupController],
            bindings: {
                onFinishClick: '<',
                translations: '<'
            }
        })

    function SurveyFinishPopupController($element) {
        const ctrl = this;

        ctrl.close = close;

        function close() {
            ctrl.onFinishClick && ctrl.onFinishClick()
            $element.remove();
        }
    }
})();