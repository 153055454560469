(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('timer', {
            template: require('./TimerComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$filter', TimerComponentController],
            bindings: {
                duration: '<',
                onTimeOver: '&',
            }
        })
    
    function TimerComponentController($scope, $filter) {
        const ctrl = this;
        
        ctrl.$onInit = onInit;
        ctrl.$onDestroy = destroy;
        ctrl.startCounter = startCounter;

        function onInit() {
            ctrl.duration = ctrl.duration * 60;
            ctrl.time = getTimerLabel(ctrl.duration);
            ctrl.startCounter();
        }
        
        function startCounter() {
            const endTime = new Date().getTime() + ctrl.duration * 1000;

            ctrl.timer = setInterval(() => {
                ctrl.duration = Math.ceil((endTime - new Date().getTime()) / 1000);

                if (ctrl.duration <= 10) {
                    ctrl.showAlert = true;
                }

                if (ctrl.duration <= 0) {
                    ctrl.onTimeOver();
                    clearTimerInterval();
                }
                
                $scope.$apply(() => {
                    ctrl.time = getTimerLabel(ctrl.duration);
                });
            }, 1000);
        }

        function getTimerLabel(time) {
            const minutes = Math.floor(time / 60),
                seconds = time - minutes * 60;
            return (`${minutes > 0 ? minutes : '0'}` + $filter('translate')('TRAINING.MINUTES')[0] + ' ' 
                + `${seconds > 0 ? seconds : '0'}` + $filter('translate')('TRAINING.SECONDS'));
        }

        function destroy() {
            clearTimerInterval();
        }

        function clearTimerInterval() {
            clearInterval(ctrl.timer);
        }
    }
})();

