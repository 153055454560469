(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('trainingFiltersPopup', {
            template: require('./TrainingFiltersPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', TrainingFiltersController],
            bindings: {
                filtersData: '<',
                onFilter: '<',
                filterModel: '<'
            }
        })

    function TrainingFiltersController($element) {
        var ctrl = this, popup;

        ctrl.applyFilters = applyFilters;
        ctrl.close = close;
        ctrl.onPopupRegistered = onPopupRegistered;

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function close() {
            $element.remove();
            popup.remove();
        }

        function applyFilters() {
            ctrl.onFilter &&
            ctrl.onFilter(ctrl.filterModel).then(() => {
                close();
            })
        }
    }
})();