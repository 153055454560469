(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('myProgress', {
            template: require('./MyProgressComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$http', '$stateParams', MyProgressController],
            bindings: {
                points: '<',
                badges: '<'
            }
        });

    function MyProgressController($http, $stateParams) {
        var ctrl = this;

        ctrl.$onInit = init;

        function init() {
            $http.post('/Training/Training/GetMyProgressPage', {
                token: $stateParams.token
            }).then(function (resp) {
                ctrl.points = resp.data.Points;
                ctrl.badges = resp.data.Badges;
            })
        }
    }
})();