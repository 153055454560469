(() => {
    'use strict';

    angular.module('App.Training')
        .component('trainingVoluntaryPage', {
            template: require('./TrainingVoluntaryPageComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$q', '$scope', '$state', '$filter', '$timeout', 'ToastFactory', 'Page', 'TrainingVoluntaryService', TrainingVoluntaryController],
            bindings: {
                searchString: '<',
                currentTab: '<',
                showTranslated: '<',
            }
        });

    function TrainingVoluntaryController($q, $scope, $state, $filter, $timeout, ToastFactory, Page, TrainingVoluntaryService) {
        const ctrl = this;
        let sourceCategories, sourceModules, scrollEl, watcher;

        ctrl.$onInit = init;
        ctrl.search = search;
        ctrl.openLesson = openLesson;
        ctrl.$onDestroy = destroy;
        ctrl.setTranslation = setTranslation;

        function init() {
            $state.params.options = {};
            ctrl.TABS = {
                CATEGORIES: 0,
                TRAINING: 1
            };

            ctrl.isLaoding = true;
            ctrl.currentTab = ctrl.currentTab ? ctrl.currentTab : ctrl.TABS.CATEGORIES;

            $q.all([TrainingVoluntaryService.getCategories(), TrainingVoluntaryService.getModules()])
                .then(data => {
                    ctrl.isLaoding = false;

                    sourceCategories = data[0].Items;
                    sourceModules = data[1].Modules;

                    ctrl.modules = sourceModules;
                    ctrl.categories = sourceCategories;

                    watcher = $scope.$watch('ctrl.currentTab', function (newVal) {
                        if (newVal === ctrl.TABS.CATEGORIES && (ctrl.searchString !== ctrl.lastCategoriesSearch)) {
                            search(ctrl.searchString);
                        }
                        if (newVal === ctrl.TABS.TRAINING && (ctrl.searchString !== ctrl.lastModulesAndLessonsSearch)) {
                            search(ctrl.searchString);
                        }
                        $state.params.options.currentTab = newVal;
                    });
                });

            $timeout(() => {
                angular.element('html, body').scrollTop(0);
                scrollEl = document.querySelector('training-voluntary-page > section');
            })

        }

        function search(query) {
            query = (query ? query : '').toLowerCase();
            ctrl.isSearchInProgress = true;

            $state.params.options.searchString = query;

            if (ctrl.currentTab === ctrl.TABS.CATEGORIES) {
                searchInCategories(query);
            } else {
                searchInModulesAndLessons(query)
            }
        }

        function searchInCategories(query) {
            ctrl.lastCategoriesSearch = query;
            ctrl.categories = sourceCategories.filter(function (category) {
                return category.Title.toLowerCase().indexOf(query) !== -1
            });
            ctrl.isCategoriesFiltered = query !== '';
            ctrl.isSearchInProgress = false;
        }

        function searchInModulesAndLessons(query) {
            if (query && query.length) {
                ctrl.lastModulesAndLessonsSearch = query;
                TrainingVoluntaryService.searchModulesAndLessons(query)
                    .then(data => {
                        ctrl.modules = data.Modules;
                        ctrl.lessons = data.Lessons;
                        ctrl.modulesLimit = 2;
                        ctrl.lessonsLimit = 2;
                        ctrl.isSearchInProgress = false;
                    });
            } else {
                ctrl.modules = sourceModules;
                ctrl.lessons = [];
                ctrl.modulesLimit = sourceModules.length;
                ctrl.isSearchInProgress = false;
            }
        }

        function openLesson(lesson) {
            if (lesson.CanBeTaken) {
                Page.stateGo('trainingLesson', lesson.CourseLessonToken, null, Page.getPageTransition());
            } else {
                ToastFactory.warning($filter('translate')('TRAINING.LESSON.NOT_ALLOWED'));
            }
        }

        function setTranslation(item) {
            item.TranslatableEntity.PreferTranslatedVersion = ctrl.showTranslated;
            return item;
        }

        function destroy() {
            watcher && watcher();
        }
    }
})();