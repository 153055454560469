(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('lessonImageQuestion', {
            template: require('./LessonImageQuestion.html'),
            controllerAs: 'ctrl',
            bindings: {
                question: '<',
                onSelectAnswer: '<',
                showAnswers: '<',
                expired: '<'
            }
        })
})();