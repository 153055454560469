(() => {
    'use strict';

    angular
        .module('App.GlobalSearch')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('search', {
                    parent: 'base',
                    url: '/search',
                    params: {
                        backState: null,
                        data: {}
                    },
                    resolve: {
                        searchSettings: () => true
                    },
                    template: `<search-page class="view-page"></search-page>`,
                    controller: 'SearchController as ctrl',
                })
        }])
        .controller('SearchController', ['$rootScope', '$stateParams', 'Page',
            function ($rootScope, $stateParams, Page) {
                if ($stateParams.backState) {
                    Page.showBackButton($stateParams.backState.state,
                        $stateParams.backState.token,
                        $stateParams.backState.extra,
                        null,
                        $stateParams.backState.options
                    );
                } else {
                    Page.showBackButton('home');
                }

                Page.setTitle('SEARCH.TITLE');
            }]);
})();