(function () {
    'use strict';
    var app = angular.module('App.Filters');

    app.filter('toPersents', [
        function () {
            return function (input) {
                return Math.round(input * 100)
            }
        }
    ]);
})();
