(() => {
    'use strict';

    angular.module('App.Training')
        .factory('TrainingVoluntaryService', ['$http', '$q', function ($http, $q) {

            return {
                getCategories: getCategories,
                getRelated: getRelated,
                getModules: getModules,
                searchModulesAndLessons: searchModulesAndLessons
            };

            function getCategories() {
                return $http.get('Training/Category/GetList').then(function (resp) {
                    return resp.data
                })
            }

            function getModules() {
                return $http.get('/Training/CourseModule/GetList').then(function (resp) {
                    return resp.data
                })
            }

            function searchModulesAndLessons(searchString) {
                return $http.post('/Training/Voluntary/Get', {
                    SearchText: searchString
                }).then(function (resp) {
                   return resp.data
                });
            }

            function getRelated(token) {
                var getRelatedCategories = $http.get('Training/Category/GetRelatedList/' + token),
                    getChildCategories = $http.get('Training/Category/GetChildList/' + token),
                    getModules = $http.get('/Training/CourseModule/GetListByCategory/' + token);

                return $q.all([getRelatedCategories, getChildCategories, getModules]).then(function (resp) {
                    return {
                        RelatedCategories: resp[0].data.Items || [],
                        ChildCategories: resp[1].data.Items || [],
                        PageTitle: resp[2].data.PageTitle,
                        AccountModuleToken: resp[2].data.AccountModuleToken,
                        Modules: resp[2].data.Modules,
                        Color: resp[2].data.Color,
                        CategoryImage: resp[2].data.CategoryImage,
                    }
                })
            }
        }])
})();   