(() => {
    'use strict';

    angular.module('App.Training')
        .component('trainingManagerTab', {
            template: require('./TrainingManagerTabComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', 'TrainingManagerTabService', 'TrainingFiltersPopupService', 'TrainingService', TrainingManagerTabController],
            bindings: {
                trainingSettings: '<'
            }
        });

    function TrainingManagerTabController($scope, TrainingManagerTabService, TrainingFiltersPopupService, TrainingService) {
        var ctrl = this, filterData, filterModel;

        ctrl.isDataLoaded = false;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.updateStatistics = updateStatistics;
        ctrl.calculateGroupScore = calculateGroupScore;
        ctrl.openFiltersPopup = openFiltersPopup;

        function init() {
            TrainingManagerTabService
                .getTrainingTabData()
                .then(data => {
                    filterData = {
                        courses: data.Courses,
                        modules: data.Modules,
                        departments: data.Departments,
                        roles: data.Roles,
                    }

                    ctrl.Counts = data.Counts;
                    ctrl.Participants = data.Participants;
                    ctrl.isDataLoaded = true;

                    TrainingFiltersPopupService.filterDataLoaded = true;
                    TrainingService.setHeaderButtons(ctrl.trainingSettings);
                });

            TrainingFiltersPopupService.setOpenFilterFunction(openFiltersPopup)
        }

        function updateStatistics(model) {
            filterModel = model
            return TrainingManagerTabService
                .getManagerStatistics(
                    model.department?.DepartmentId,
                    model.role?.RoleId,
                    model.course?.CourseId,
                    model.module?.CourseModuleId
                )
                .then(data => {
                    ctrl.Participants = data.Participants
                    ctrl.Counts = data.Counts;

                    return data
                });
        }

        function calculateGroupScore(groupLength) {
            return ctrl.Participants.length === 0 ? 0 : Math.round(groupLength * 100 / ctrl.Participants.length);
        }

        function openFiltersPopup() {
            TrainingFiltersPopupService.open(filterData, filterModel, updateStatistics);
        }

        function destroy() {
            TrainingFiltersPopupService.destroy();
            TrainingFiltersPopupService.filterDataLoaded = false;
        }
    }
})();