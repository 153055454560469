(() => {
    'use strict';
    
    angular
        .module('App.Filters')
        .filter('secureHtml', ['$sce', secureHtml]);

    function secureHtml($sce) {
        return (input, ignoreLinks) => {
            if (input?.length) {
                const text = ignoreLinks ? input : Autolinker.link(input, {
                    stripPrefix: false
                });

                return $sce.trustAsHtml(text);
            } else
                return null;
        }
    }
})();