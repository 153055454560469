(() => {
    'use strict';

    angular
        .module('App.Training')
        .factory('TrainingService', ['$http', '$filter', '$timeout', '$state', 'Page', 'ConfirmPopupService',
            'TrainingFiltersPopupService', 'HeaderButtonsFactory', 'TRAINING_ROUTE', 'ResponsiveService', TrainingService]);

    function TrainingService($http, $filter, $timeout, $state, Page, ConfirmPopupService, TrainingFiltersPopupService,
                             HeaderButtonsFactory, TRAINING_ROUTE, ResponsiveService) {
        const trainingRoutesTitles = {
            'trainingMy': 'TRAINING.MY_TRAINING',
            'trainingProgress': 'TRAINING.MY_PROGRESS',
            'trainingManager': 'TRAINING.MANAGER',
            'trainingVoluntary': 'TRAINING.VOLUNTARY'
        };

        return {
            getMyTrainingData: getMyTrainingData,
            getItemStatus: getItemStatus,
            getItemStatusClass: getItemStatusClass,
            scrollToTrainingItem: scrollToTrainingItem,
            getDurationString: getDurationString,
            showScormUpdateAlert: showScormUpdateAlert,
            selectTranslation: selectTranslation,
            setHeaderButtons: setHeaderButtons,
            trainingRoutesTitles
        };

        function getMyTrainingData(token) {
            return $http
                .get('/Training/Training/Get/' + token)
                .then(resp => resp.data);
        }

        function getItemStatus(item) {
            if (!item.CanBeTaken) {
                return $filter('translate')('TRAINING.STATE.LOCKED');
            }
            if (item.New) {
                return $filter('translate')('TRAINING.STATE.NEW') + '!';
            }
            if (item.State === 1) {
                return $filter('translate')('TRAINING.STATE.NOT_STARTED');
            }
            if (item.State === 2) {
                if (item.CompletionRate) {
                    let status = $filter('translate')('TRAINING.STATE.COMPLETED_RATE');
                    status = status.charAt(0).toLowerCase() + status.substring(1);

                    return Math.round(item.CompletionRate * 100) + '% ' + status;
                } else {
                    return $filter('translate')('TRAINING.STATE.IN_PROGRESS');
                }

            }
            if (item.State === 3) {
                if (item.Passed) {
                    return $filter('translate')('TRAINING.STATE.PASSED');
                } else {
                    return $filter('translate')('TRAINING.STATE.NOT_PASSED');
                }
            }
        }

        function getItemStatusClass(item) {
            if (item.Expired) {
                return 'expired';
            }
            if (!item.CanBeTaken) {
                return 'locked';
            }
            if (item.State !== 3 && (item.DeadlineStatus === 1 || item.DeadlineStatus === 2)) {
                return 'deadline'
            }
            if (item.New) {
                return 'new';
            }
            if (item.State === 1) {
                return 'not-started'
            }
            if (item.State === 2) {
                return 'in-progress';
            }
            if (item.State === 3) {
                if (item.Passed) {
                    return 'passed';
                } else {
                    return 'not-passed';
                }
            }
        }

        function scrollToTrainingItem(element) {
            $timeout(() => {
                const scrollTo = [
                    element.find('.in-progress'),
                    element.find('.new'),
                    element.find('.not-started'),
                    element.find('.not-passed')
                ];

                scrollTo.forEach(item => {
                    if (item.length > 0) {
                        $('html, body').scrollTop($(_.first(item)).offset().top - $('.base-top').outerHeight() - 10);

                        return false;
                    }
                });
            });
        }

        function getDurationString(item) {
            let duration;

            if (item.TimedQuestionFlowEnabled && item.TimedTrainingLimitInMinutes > 0) {
                duration = item.TimedTrainingLimitInMinutes * 60;
            } else {
                duration = item.Duration !== undefined ? item.Duration : item.LessonsDuration;
            }

            return duration < 60 ?
                (duration + ' ' + $filter('translate')('TRAINING.SECONDS')) :
                (Math.round(duration / 60) + ' ' + $filter('translate')('TRAINING.MINUTES'))
        }


        function showScormUpdateAlert() {
            ConfirmPopupService.open({
                title: 'TRAINING.SCORM_POPUP.TITLE',
                message: 'TRAINING.SCORM_POPUP.BODY',
                yesText: 'OK',
                isAlert: true
            })
        }

        function selectTranslation(preferTranslatedVersion) {
            return $http
                .post('/Training/Training/SaveUserPreference', {preferTranslatedVersion})
                .then(resp => resp.data);
        }

        function setHeaderButtons(settings) {
            HeaderButtonsFactory.createButtonsList(
                () => getHeaderButtons(settings)
            );
        }

        function getHeaderButtons(settings) {
            const subItems = [];

            if (settings.IsMandatoryAllowed) {
                subItems.push(generateItem(TRAINING_ROUTE.MY_TRAINING));
            }

            subItems.push(generateItem(TRAINING_ROUTE.MY_PROGRESS));

            if (settings.IsManagerViewEnabled && settings.IsMandatoryAllowed) {
                subItems.push(generateItem(TRAINING_ROUTE.MANAGER));
            }
            if (settings.IsVoluntaryAllowed) {
                subItems.push(generateItem(TRAINING_ROUTE.VOLUNTARY));
            }

            const items = [
                {
                    icon: 'arrow-bottom',
                    activeIcon: 'arrow-top',
                    place: 'title',
                    items: subItems
                }
            ];

            if (!ResponsiveService.isDesktop()) {
                items.push({
                    icon: 'filter',
                    onClick: () => {
                        TrainingFiltersPopupService.openFilterFunction();
                    },
                    place: 'right',
                    hidden: $state.current.name !== TRAINING_ROUTE.MANAGER || !TrainingFiltersPopupService.isFilterDataLoaded()
                })
            }

            return items
        }

        function generateItem(route) {
            return {
                title: trainingRoutesTitles[route],
                onClick: () => {
                    Page.stateGo(route, $state.params.token, null, Page.getPageTransition())
                },
                active: $state.current.name === route
            }
        }
    }
})();