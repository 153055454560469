(() => {
    'use strict';

    angular
        .module('App.Common')
        .factory('ResponsiveService', ['$window', '$rootScope', function ($window, $rootScope) {
            let resizeTimer,
                mediaState = getState();

            window.addEventListener('resize', () => {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(() => {
                    if (mediaState !== getState()) {
                        mediaState = getState();
                        $rootScope.$broadcast('resize', mediaState);
                    }
                }, 250);
            });

            return {
                isMobile: isMobile,
                isDesktop: isDesktop,
                getDevice: getState,
                watch: watch
            }

            function getState() {
                if (isMobile()) {
                    return 'mobile'
                }
                if (isBigTabletPortrait()) {
                    return 'bigTablet.portrait'
                }
                if (isSmallTabletPortrait()) {
                    return 'smallTablet.portrait'
                }
                if (isSmallTabletLandscape()) {
                    return 'smallTablet.landscape'
                }
                if (isBigTabletLandscape()) {
                    return 'bigTablet.landscape'
                }
                if (isDesktop()) {
                    return 'desktop'
                }
            }

            function isMobile() {
                return $window.matchMedia("screen " +
                    "and (max-width: 767px)").matches;
            }

            function isSmallTabletPortrait() {
                return $window.matchMedia("screen " +
                    "and (min-width: 768px) " +
                    "and (max-width: 1024px) " +
                    "and (orientation: portrait)").matches;
            }

            function isSmallTabletLandscape() {
                return $window.matchMedia("screen " +
                    "and (min-width: 768px) " +
                    "and (max-width: 1024px) " +
                    "and (orientation: landscape)").matches;
            }

            function isBigTabletPortrait() {
                return $window.matchMedia("screen " +
                    "and (min-device-width: 1024px) " +
                    "and (max-device-width: 1366px) " +
                    "and (min-height: 1366px) " +
                    "and (orientation: portrait) " +
                    "and (-webkit-min-device-pixel-ratio: 1.5)").matches;
            }

            function isBigTabletLandscape() {
                return $window.matchMedia("screen " +
                    "and (min-device-width: 1024px) " +
                    "and (max-device-width: 1366px) " +
                    "and (orientation: landscape) " +
                    "and (-webkit-min-device-pixel-ratio: 1.5)").matches;
            }

            function isDesktop() {
                return $window.matchMedia("screen and (min-width: 1025px)").matches;
            }

            function watch(callback) {
                return $rootScope.$on('resize', (ev, data) => {
                    $rootScope.$apply(() => {
                        callback(data, ev);
                    })
                });
            }
        }]);
})();