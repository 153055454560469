(function () {
    'use strict';
    var app = angular.module('App.Filters');

    app.filter('dateTime', [
        function () {
            return function (input) {
                return moment(input).format('H:mm | D MMM YYYY');
            }
        }
    ]);
})();
