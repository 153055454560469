(function () {
    'use strict';

    angular.module('App')
        .component('surveyList', {
            template: require('./SurveyListComponent.html'),
            controllerAs: 'ctrl',
            controller: ['Page', '$timeout', 'HeaderButtonsFactory', 'ResponsiveService', SurveyListComponentController],
            bindings: {
                surveys: '<',
                moduleToken: '<',
                tab: '<'
            }
        });

    function SurveyListComponentController(Page, $timeout, HeaderButtonsFactory, ResponsiveService) {
        var ctrl = this;

        ctrl.TABS = {
            'ACTIVE_SURVEYS': 0,
            'EXPIRED_SURVEYS': 1
        }

        ctrl.$onInit = init;
        ctrl.openSurvey = openSurvey;
        ctrl.$onDestroy = destroy;

        ctrl.isDesktop = ResponsiveService.isDesktop();

        function init() {
            $timeout(() => {
                ctrl.isLoaded = true;
            }, 250);
        }

        function openSurvey(survey) {
            Page.stateGo('survey', ctrl.moduleToken, survey.SurveyToken, Page.getPageTransition(), null, true, {
                expired: ctrl.tab === ctrl.TABS.EXPIRED_SURVEYS
            });
        }

        function destroy() {
            if (ctrl.isDesktop) {
                HeaderButtonsFactory.resetButtonsList()
            }
        }
    }
})();
