(() => {
    'use strict';
    angular
        .module('App.Training')
        .component('badgePopup', {
            template: require('./BadgePopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', BadgePopupController],
            bindings: {
                badge: '<',
                onClose: '<',
            }
        });

    function BadgePopupController($element) {
        const ctrl = this;

        ctrl.close = close;

        function close() {
            $element.remove()
            ctrl.onClose();
        }
    }
})();