(() => {
    'use strict';

    angular
        .module('App.Catalog')
        .factory('CatalogFactory', ['$http', '$q', CatalogFactory]);

    function CatalogFactory($http, $q) {
        let lastSearchCanceler;

        return {
            search: search,
        };

        function search(query, token) {
            const canceller = $q.defer();

            if (lastSearchCanceler) {
                lastSearchCanceler.resolve();
                lastSearchCanceler = undefined;
            }

            const promise = $http.post('/SearchV2/SearchCatalog', {
                AccountModuleId: token,
                SearchText: query.searchText,
                Limit: query.limit || 20,
                AfterEntityId: query.afterEntityId,
                AfterEntityType: query.afterEntityType,
            }, {timeout: canceller.promise}).then(resp => resp.data);

            lastSearchCanceler = canceller;

            return promise;
        }
    }
})();