(function () {
    'use strict';
    var app = angular.module('App.Filters');

    app.filter('placeholder', ['$translate', 
        function ($translate) {
            var translatePlaceholders = [
                'MONTH.NOW.NAME', 'MONTH.NEXT.NAME', 'MONTH.PREVIOUS.NAME',
                'MONTH.NOW.SHORTNAME', 'MONTH.NEXT.SHORTNAME', 'MONTH.PREVIOUS.SHORTNAME', 'DAY.NOW.NAME'
            ];
            var basicPlaceholders = [
                'YEAR.NOW', 'YEAR.NEXT', 'YEAR.PREVIOUS',
                'MONTH.NOW', 'MONTH.NEXT', 'MONTH.PREVIOUS', 'WEEK.NOW.NUMBER'
            ];

            function filterReturn(input) {
                input = filterBasic(input);
                input = filterTranslate(input);
                return input;
            }

            function filterBasic(input) {
                for (var i = 0; i < basicPlaceholders.length; i++) {
                    if (input.indexOf('#' + basicPlaceholders[i] + '#') >= 0) {
                        switch (basicPlaceholders[i]) {
                            
                            case 'WEEK.NOW.NUMBER': input = input.replace('#WEEK.NOW.NUMBER#', moment().format('w')); break;
                            case 'YEAR.NOW': input = input.replace('#YEAR.NOW#', moment().year()); break;
                            case 'YEAR.NEXT': input = input.replace('#YEAR.NEXT#', moment().add(1, 'y').year()); break;
                            case 'YEAR.PREVIOUS': input = input.replace('#YEAR.PREVIOUS#', moment().subtract(1, 'y').year()); break;
                            case 'MONTH.NOW': input = input.replace('#MONTH.NOW#', moment().month() + 1); break;
                            case 'MONTH.NEXT': input = input.replace('#MONTH.NEXT#', moment().add(1, 'M').month() + 1); break;
                            case 'MONTH.PREVIOUS': input = input.replace('#MONTH.PREVIOUS#', moment().subtract(1, 'M').month() + 1); break;
                        }
                        return filterBasic(input);
                    }
                }

                return input;
            }

            function filterTranslate(input) {
                for (var i = 0; i < translatePlaceholders.length; i++) {
                    if (input.indexOf('#' + translatePlaceholders[i] + '#') >= 0) {
                        var m = moment();
                        m.locale($('html').attr('lang'));
                        switch (translatePlaceholders[i]) {
                            case 'DAY.NOW.NAME': input = input.replace('#DAY.NOW.NAME#', m.format('dddd')); break;
                            case 'MONTH.NOW.NAME': input = input.replace('#MONTH.NOW.NAME#', m.format('MMMM')); break;
                            case 'MONTH.NOW.SHORTNAME': input = input.replace('#MONTH.NOW.SHORTNAME#', m.format('MMM')); break;
                            case 'MONTH.NEXT.NAME': input = input.replace('#MONTH.NEXT.NAME#', m.add(1, 'M').format('MMMM')); break;
                            case 'MONTH.NEXT.SHORTNAME': input = input.replace('#MONTH.NEXT.SHORTNAME#', m.add(1, 'M').format('MMM')); break;
                            case 'MONTH.PREVIOUS.NAME': input = input.replace('#MONTH.PREVIOUS.NAME#', m.subtract(1, 'M').format('MMMM')); break;
                            case 'MONTH.PREVIOUS.SHORTNAME': input = input.replace('#MONTH.PREVIOUS.SHORTNAME#', m.subtract(1, 'M').format('MMM')); break;
                        }
                        return filterTranslate(input);
                    }
                }

                return input;
            }

            return filterReturn;
        }
    ]);
})();