(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('badge', {
            template: require('./BadgeComponent.html'),
            controllerAs: 'ctrl',
            controller: [BadgeController],
            bindings: {
                badge: '<'
            }
        });

    function BadgeController() {
        var ctrl = this;

        ctrl.$onInit = init;

        function init() {
            switch (ctrl.badge.BadgeStatus) {
                case 1:
                    ctrl.badgeClass = 'active';
                    break;
                case 2:
                    ctrl.badgeClass = 'not-active'
                    break;
                case 3:
                    ctrl.badgeClass = 'not-achieved'
                    break;
            }
        }
    }
})();