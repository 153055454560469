var NativeHelper = NativeHelper || {
    _initialized: false,
    _isWrapperApp: false,
    _isAndroid: false,
    _isIOS: false,
    _availableMethods: null,
    _appInfo: null,
    init: function (callback) {
        if (navigator && navigator.userAgent) {
            // Handle as lowercase to prevent casing problems
            var userAgent = navigator.userAgent.toLowerCase();

            // Determine if is wrapper app
            NativeHelper._isWrapperApp = userAgent.indexOf("relesys_web_client") !== -1;

            if (NativeHelper._isWrapperApp) {
                // Determine Android
                NativeHelper._isAndroid = (NativeHelper._isWrapperApp && userAgent.indexOf('android') !== -1 && typeof NativeBridge !== 'undefined');
                // Determine iOS
                NativeHelper._isIOS = (NativeHelper._isWrapperApp && (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers['NativeBridge_basic']));

                if (!NativeHelper._isAndroid && !NativeHelper._isIOS) {
                    // it is neither OS so we ignore the wrapper statement
                    NativeHelper._isWrapperApp = false;
                }

                if (NativeHelper._isWrapperApp) {
                    NativeHelper.methods.basic.getAvailableMethods(function (amData) {
                        if (amData.status === 1) {
                            _availableMethods = amData;

                            NativeHelper.methods.basic.getAppInfo(function (appInfoData) {
                                if (appInfoData.status === 1) {
                                    if (callback) {
                                        callback({ status: 1, data: appInfoData.data });
                                    }
                                } else {
                                    if (callback) {
                                        callback({ status: 0, data: null });
                                    }
                                }
                            });
                        } else {
                            if (callback) {
                                callback({ status: 0, data: null });
                            }
                        }
                    });
                } else {
                    if (callback) {
                        callback({ status: 1, data: null });
                    }
                }
            }
        } else {
            if (callback) {
                callback({ status: 1, data: null });
            }
        }

        // Set as initialized
        NativeHelper._initialized = true;
        // return promise
        return;
    },
    isWrapperApp: function () {
        return NativeHelper._isWrapperApp;
    },
    isAndroid: function () {
        return NativeHelper._isAndroid;
    },
    isIOS: function () {
        return NativeHelper._isIOS;
    },
    hasMethod: function (path) {
        var area = path.split('.')[0];
        var methodName = path.split('.')[1];

        return NativeBridgeCallback.hasMethod(methodName, area);
    },
    _runMethod: function (ios, android, callback, attrs, functionName) {
        if(!callback){
            callback = () => {};
        }
        
        if (NativeHelper._isIOS) {
            ios(callback, attrs);
            return;
        } else if (NativeHelper._isAndroid) {
            android(callback, attrs);
            return;
        }

        if(window.RELESYS_NATIVE_HELPER_DEBUG && window.RELESYS_NATIVE_HELPER_DEBUG[functionName]){
            window.RELESYS_NATIVE_HELPER_DEBUG[functionName](callback, attrs);
            return;
        }

        callback({ status: 0, data: null });
        return;
    },
    methods: {
        basic: {
            appIsReady: function (callback, readyObject) {
                NativeHelper._runMethod(iOSBridge.basic.appIsReady, AndroidBridge.basic.appIsReady, callback, readyObject);
            },
            readyForWebClientConfiguration: function (callback) {
                NativeHelper._runMethod(iOSBridge.basic.readyForWebClientConfiguration, AndroidBridge.basic.readyForWebClientConfiguration, callback);
            },
            getAppInfo: function (callback) {
                if (!NativeHelper._appInfo) {
                    NativeHelper._runMethod(iOSBridge.basic.getAppInfo, AndroidBridge.basic.getAppInfo, callback);
                    return;
                }
                
                callback && callback({ status: 1, data: NativeHelper._appInfo });
                return;
            },
            getAvailableMethods: function (callback) {
                if (!NativeHelper._availableMethods) {
                    NativeHelper._runMethod(iOSBridge.basic.getAvailableMethods, AndroidBridge.basic.getAvailableMethods, callback);
                    return;
                }

                callback && callback({ status: 1, data: NativeHelper._availableMethods });
                return;
            },
            reload: function (callback) {
                NativeHelper._runMethod(iOSBridge.basic.reload, AndroidBridge.basic.reload, callback);
            }
        },
        user: {
            isLoggedIn: function (callback, allowPush) {
                NativeHelper._runMethod(iOSBridge.user.isLoggedIn, AndroidBridge.user.isLoggedIn, callback, allowPush);
            },
            isLoggedOut: function (callback) {
                NativeHelper._runMethod(iOSBridge.user.isLoggedOut, AndroidBridge.user.isLoggedOut, callback);
            },
            getLoginStatus: function (callback) {
                NativeHelper._runMethod(iOSBridge.user.getLoginStatus, AndroidBridge.user.getLoginStatus, callback);
            }
        },
        biometric: {
            getStatus: function(callback, userToken) {
                var request = {
                    userToken: userToken
                };
                NativeHelper._runMethod(iOSBridge.biometric.getStatus, AndroidBridge.biometric.getStatus, callback, request, 'biometric.getStatus');
            },
            getPin: function(callback, userToken) {
                var request = {
                    userToken: userToken
                };
                NativeHelper._runMethod(iOSBridge.biometric.getPin, AndroidBridge.biometric.getPin, callback, request, 'biometric.getPin');
            },
            savePin: function(callback, userToken, pinCode) {
                var request = {
                    userToken: userToken,
                    pinCode: pinCode
                };
                NativeHelper._runMethod(iOSBridge.biometric.savePin, AndroidBridge.biometric.savePin, callback, request, 'biometric.savePin');
            },
            clearPin: function(callback, userToken) {
                var request = {
                    userToken: userToken
                };
                NativeHelper._runMethod(iOSBridge.biometric.clearPin, AndroidBridge.biometric.clearPin, callback, request, 'biometric.clearPin');
            }
        },
        push: {
            togglePush: function (callback, enabled) {
                NativeHelper._runMethod(iOSBridge.push.togglePush, AndroidBridge.push.togglePush, callback, enabled);
            },
            getToken: function (callback) {
                NativeHelper._runMethod(iOSBridge.push.getToken, AndroidBridge.push.getToken, callback);
            },
            setBadge: function (callback, badge) {
                NativeHelper._runMethod(iOSBridge.push.setBadge, AndroidBridge.push.setBadge, callback, badge);
            },
            sendLocalPush: function (callback, title, body, badge, payload) {
                var obj = {
                    title: title,
                    body: body
                };
                if (badge !== undefined && badge !== null) {
                    obj.badge = badge;
                }
                if (payload !== undefined && payload !== null) {
                    obj.payload = payload;
                }
                NativeHelper._runMethod(iOSBridge.push.sendLocalPush, AndroidBridge.push.sendLocalPush, callback, obj);
            }
        },
        visual: {
            changeStatusBar: function (callback, backgroundColor, textColor) {
                var obj = {
                    background: backgroundColor,
                    text: textColor
                };
                NativeHelper._runMethod(iOSBridge.visual.changeStatusBar, AndroidBridge.visual.changeStatusBar, callback, obj);
            },
            setLoadVisuals: function (callback, settings) {
                NativeHelper._runMethod(iOSBridge.visual.setLoadVisuals, AndroidBridge.visual.setLoadVisuals, callback, settings);
            },
            resetLoadVisuals: function (callback) {
                NativeHelper._runMethod(iOSBridge.visual.resetLoadVisuals, AndroidBridge.visual.resetLoadVisuals, callback);
            }
        },
        scanner: {
            scanBarcode: function (callback, formats) {
                NativeHelper._runMethod(iOSBridge.scanner.scanBarcode, AndroidBridge.scanner.scanBarcode, callback, formats);
            }
        },
        sharing: {
            shareOnFacebook: function (callback, url, quote) {
                var obj = {
                    url: url,
                    quote: quote
                };
                NativeHelper._runMethod(iOSBridge.sharing.shareOnFacebook, AndroidBridge.sharing.shareOnFacebook, callback, obj);
            }
        }
    }
};

window.NativeHelper = NativeHelper;

const nameOf = (f) => (f).toString().replace(/[ |\(\)=>]/g,'');