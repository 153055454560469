(() => {
    'use strict';

    angular
        .module('App.Training')
        .config(['$stateProvider', ($stateProvider) => {
            $stateProvider
                .state('trainingLesson', {
                    parent: 'base',
                    url: '/training/lesson/:token/:extra',
                    params: {
                        data: {},
                        backState: null,
                    },
                    templateProvider: ['Page', (Page) => {
                        return Page.getSettings().AdditionalQuestionTypes ?
                            '<lesson-page-v2 class="view-page"></lesson-page-v2>' :
                            '<lesson-page class="view-page" lesson-data="ctrl.lessonData"></lesson-page>';
                    }],
                    controller: 'LessonPageController as ctrl'
                })
        }])
        .controller('LessonPageController', ['$stateParams', 'LessonPageService', 'Page', LessonPageController]);

    function LessonPageController($stateParams, LessonPageService, Page) {
        const ctrl = this;

        if (Object.keys($stateParams.data).length) {
            const position = $stateParams.data.lessonIndex + 1 + '/' + $stateParams.data.lessons;
            ctrl.lessonData = $stateParams.data;

            Page.setTitle($stateParams.data.lesson.Title, position);
        }

        if ($stateParams.backState) {
            Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra, null, $stateParams.backState.options);
        } else {
            Page.showBackButton('home');
        }
    }
})();
