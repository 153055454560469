(() => {
    'use strict';

    angular
        .module('App.Social')
        .component('votersPopup', {
            template: require('./VotersPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$http', VotersPopupController],
            bindings: {
                token: '<',
                pollOptionId: '<'
            }
        })

    function VotersPopupController($element, $http) {
        var ctrl = this, popup;

        ctrl.onOpen = getVoters;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.close = close;

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function close() {
            popup.remove();
            $element.remove();
        }

        function getVoters() {
            $http.post('Social/GetPollVoters', {
                token: ctrl.token,
                pollOptionId: ctrl.pollOptionId
            }).then(function (resp) {
                ctrl.voters = resp.data.Items;
            })
        }
    }
})();