(() => {
    'use strict';

    angular
        .module('App.Training')
        .factory('LessonQuestionsService', ['TRAINING_QUESTION_TYPE', '$filter', LessonQuestionsService]);

    function LessonQuestionsService(TRAINING_QUESTION_TYPE, $filter) {
        return {
            getQuestionByIndex: getQuestionByIndex,
            setStackVisibility: setStackVisibility,
            getAnswerMessage: getAnswerMessage
        }


        function getQuestionByIndex(ind) {
            return document.querySelector(`.content-wrapper .question-wrapper[data-index='${ind}']`)
        }

        function setStackVisibility(currentInd, questions) {
            clearClasses();
            let cardsInStack = 0;
            do {
                const isCard = isCardCheck(questions[currentInd]),
                    isNextCard = isCardCheck(questions[currentInd + 1]);
                if (isCard && isNextCard) {
                    addToStack(currentInd);
                    addToStack(currentInd + 1);
                    cardsInStack = cardsInStack === 0 ? 2 : cardsInStack + 1;

                    addUnder(currentInd + 1, cardsInStack)

                    currentInd++;
                } else {
                    return cardsInStack > 0 ? cardsInStack - 1 : null
                }
            } while (cardsInStack < 3);

            return cardsInStack > 0 ? cardsInStack - 1 : null

            function addToStack(ind) {
                if (getQuestionByIndex(ind) && !getQuestionByIndex(ind).classList.contains('in-stack')) {
                    getQuestionByIndex(ind).classList.add('in-stack')
                }
            }

            function addUnder(ind, stackIndex) {
                if (getQuestionByIndex(ind) && !getQuestionByIndex(ind).classList.contains('stack-index-' + stackIndex)) {
                    getQuestionByIndex(ind).classList.add('stack-index-' + stackIndex, 'stack-below');
                }
            }

            function isCardCheck(item) {
                return item?.QuestionType === TRAINING_QUESTION_TYPE.SWIPE_CARD;
            }

            function clearClasses() {
                const cards = document.querySelectorAll('.in-stack');
                cards.forEach(card => {
                    card.classList.remove('in-stack', 'stack-below', 'stack-index-2', 'stack-index-3');
                })
            }
        }

        function getAnswerMessage(lesson) {
            return lesson.IsCorrect ?
                $filter('translate')('TRAINING.LESSON.CORRECT') :
                (lesson.AutoCreatedAfterTimeout ?
                    $filter('translate')('TRAINING.LESSON.TIMED_OUT') :
                    $filter('translate')('TRAINING.LESSON.WRONG'))
        }
    }
})();
