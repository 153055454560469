(() => {
    'use strict';

    angular.module('App.Training')
        .factory('TrainingFiltersPopupService', ['PopupWrapperService', TrainingFiltersPopupService]);

    function TrainingFiltersPopupService(PopupWrapperService) {
        let openFilter;

        return {
            open: open,
            destroy: destroy,
            openFilterFunction: openFilterFunction,
            setOpenFilterFunction: setOpenFilterFunction,
            filterDataLoaded: false,
            isFilterDataLoaded: isFilterDataLoaded
        };

        function open(data, filterModel, onFilter) {
            PopupWrapperService
                .createDynamic(
                    '<training-filters-popup ' +
                    '   filters-data="data"' +
                    '   filter-model="filterModel"' +
                    '   on-filter="onFilter"' +
                    '></training-filters-popup>',
                    {
                        data: data,
                        filterModel: filterModel,
                        onFilter: onFilter
                    })
        }

        function openFilterFunction() {
            openFilter && openFilter();
        }

        function setOpenFilterFunction(filter) {
            openFilter = filter;
        }

        function isFilterDataLoaded() {
            return this.filterDataLoaded;
        }

        function destroy() {
            openFilter = undefined;
        }
    }
})();