(() => {
    'use strict';

    angular
        .module('App.Social')
        .factory('SocialWallService', ['$rootScope', '$http', '$stateParams', '$filter', 'PopupWrapperService', SocialWallService]);

    function SocialWallService($rootScope, $http, $stateParams, $filter, PopupWrapperService) {
        let createPostOpen;

        return {
            showCreatePost: showCreatePost,
            getNewPosts: getNewPosts,
            logActivities: logActivities,
            initPullToRefresh: initPullToRefresh
        };


        function showCreatePost(ctrl, post) {
            if (!createPostOpen) {
                $rootScope.disableVisibilitychange = true;
                createPostOpen = true;
                const options = {};
    
                // Add values
                options.settings = ctrl.settings;
                options.settings.AllowCreatingPolls = ctrl.postsData.AllowCreatingPolls;
                options.settings.groupsCount = ctrl.postsData.SocialGroups?.length;
                options.settings.AccountModuleToken = ctrl.postsData.AccountModuleToken;
                options.SocialGroups = ctrl.postsData.SocialGroups;

                if (options.settings.groupsCount === 1) {
                    options.settings.SocialGroupId = ctrl.postsData.SocialGroups[0].SocialGroupId;
                }

                if (post) {
                    options.editablePost = post;
                    $http.get('/Social/EditPost/' + post.SocialPostToken).then(resp => {
                        post.selectedLanguages = resp.data.Post.Translations
                        post.AllowPollMultiSelection = resp.data.Post.AllowPollMultiSelection
                        post.UsersCanAddPollOptions = resp.data.Post.UsersCanAddPollOptions
                        post.PollDeadline = resp.data.Post.PollDeadline
                        post.PollOptions = resp.data.Post.PollOptions
                        post.PollTitle = resp.data.Post.PollTitle
                        post.PollId = resp.data.Post.PollId

                        openCreatePostPopup(options);
                        createPostOpen = false;
                    });
                } else {
                    openCreatePostPopup(options)
                    createPostOpen = false;
                }
            }
        }

        function openCreatePostPopup(data) {
            PopupWrapperService.createDynamic(
                `<social-post-create
                               settings="settings"
                               editable-post="editablePost"
                               groups="groups"
                           ></social-post-create>`,
                {
                    settings: data.settings,
                    groups: data.SocialGroups,
                    editablePost: data.editablePost,
                }
            )
        }

        function getNewPosts(socialModuleToken, timestamp, socialGroupId, sortType, startDate, endDate) {
            return $http.post('/Social/GetNewPosts', {
                accountModuleToken: socialModuleToken,
                timestamp: timestamp,
                socialGroupId: socialGroupId,
                sortType,
                startDate,
                endDate
            }).then(resp => resp.data)
        }

        function logActivities(socialModuleToken, socialGroupId) {
            return $http.post('/Social/LogActivities', {
                token: socialModuleToken,
                socialGroupId: socialGroupId
            })
        }

        function initPullToRefresh(onRefresh) {
            PullToRefresh.init({
                mainElement: 'social',
                onRefresh: onRefresh,
                iconArrow: '&#8593;',
                triggerElement: '.social-page',
                instructionsPullToRefresh: $filter('translate')('PULL_TO_REFRESH'),
                instructionsReleaseToRefresh: $filter('translate')('RELEASE_TO_REFRESH'),
                instructionsRefreshing: $filter('translate')('REFRESHING')
            });
        }
    }
})();