(() => {
    'use strict';
    angular
        .module('App.Basics')
        .factory('BasicHelper', ['$q', '$translate', function ($q, $translate) {
            return {
                getDateTime: getDateTime,
                getRandomID: getRandomID,
                getSecureRandomID: getSecureRandomID,
                getPlaceholderRows: getPlaceholderRows,
                getSettingString: getSettingString,
                parseBoolean: parseBoolean,
                translateStrings: translateStrings,
                isEmptyGuid: isEmptyGuid,
                stringIsNullOrEmpty: stringIsNullOrEmpty,
                getEmbedFromText: getEmbedFromText,
                getYoutubeEmbedFromUrl: getYoutubeEmbedFromUrl,
                getVimeoEmbedFromUrl: getVimeoEmbedFromUrl,
                isOldFF: isOldFF,
                isIOS: isIOS,
                isAndroid: isAndroid,
                isSafari: isSafari,
                isWrapperApp: isWrapperApp,
                isIOSVersionBetween: isIOSVersionBetween,
                isIOSVersionAbove: isIOSVersionAbove,
                isIOSVersionOrAbove: isIOSVersionOrAbove,
                isIOSVersion: isIOSVersion,
                isIOSVersionOrBelow: isIOSVersionOrBelow,
                isIOSVersionBelow: isIOSVersionBelow,
                mailTo: mailTo,
                hexToRgba: hexToRgba,
                decodeHtml: decodeHtml,
                CopyClipbord: CopyClipbord,
                clearTextSelection: clearTextSelection,
                getImageVariantBySize: getImageVariantBySize,
                getFlagByCultureId: getFlagByCultureId,
                copyTextToClipboard: copyTextToClipboard
            };

            function getDateTime(jsonDate) {
                return new Date(parseInt(jsonDate.substr(6)));
            }

            function getRandomID(length) {
                length = (length === undefined) ? 20 : length;
                var value = "";
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                for (var i = 0; i < length; i++)
                    value += possible.charAt(Math.floor(Math.random() * possible.length));
                return value;
            }

            function getSecureRandomID(length, onlyLowercase) {
                length = (typeof length === 'number') ? length : 32;
                onlyLowercase = (typeof onlyLowercase === 'boolean') ? onlyLowercase : false

                const characters = onlyLowercase 
                    ? 'abcdefghijklmnopqrstuvwxyz0123456789'
                    : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

                let result = '';
                
                const charactersLength = characters.length;
                const randomValues = new Uint8Array(length);
                crypto.getRandomValues(randomValues);

                for (let i = 0; i < length; i++) {
                    result += characters.charAt(randomValues[i] % charactersLength);
                }

                return result;
            }

            function getPlaceholderRows(count, minWidth, maxWidth, usePercentage) {
                var rows = [];
                for (var i = 0; i < count; i++) {
                    var width = Math.floor(Math.random() * (maxWidth - minWidth + 1) + minWidth);
                    if (usePercentage) {
                        width += '%';
                    }

                    rows.push({
                        index: i,
                        width: width
                    });
                }
                return rows;
            }

            function getSettingString(settingValue, translateString) {
                var d = $q.defer();

                if (settingValue !== undefined && settingValue !== null && settingValue.length > 0) {
                    d.resolve(settingValue);
                } else {
                    $translate(translateString).then(function (translation) {
                        d.resolve(translation);
                    });
                }

                return d.promise;
            }

            function parseBoolean(value) {
                if (_.isBoolean(value)) {
                    return value;
                } else if (_.isString(value)) {
                    return value.toLowerCase() === 'true';
                }
                return false;
            }

            function translateStrings(stringArray, output) {
                var d = $q.defer();

                if (output == undefined) {
                    output = [];
                }

                $translate(stringArray[output.length]).then(function (translation) {
                    output.push(translation);

                    if (stringArray.length > output.length) {
                        translateStrings(stringArray, output).then(function (final) {
                            d.resolve(final);
                        });
                    } else {
                        d.resolve(output);
                    }
                });

                return d.promise;
            }

            function isEmptyGuid(guid) {
                return (guid == "00000000-0000-0000-0000-000000000000");
            }

            function stringIsNullOrEmpty(value) {
                return !(!!value);
            }

            function getEmbedFromText(value) {
                if (value) {
                    var urlMatches = value.match(/(https?:\/\/[^\s]+)/g);
                    if (urlMatches) {
                        for (var i = 0; i < urlMatches.length; i++) {
                            var youtube = f.getYoutubeEmbedFromUrl(urlMatches[i]);
                            if (youtube) return youtube;
                            var vimeo = f.getVimeoEmbedFromUrl(urlMatches[i]);
                            if (vimeo) return vimeo;
                        }
                    }
                }
                return null;
            }

            function getYoutubeEmbedFromUrl(url) {
                var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
                var matches = url.match(regExp);
                if (matches && matches[2].length == 11) {
                    return 'https://www.youtube-nocookie.com/embed/' + matches[2];
                }
                return null;
            }

            function getVimeoEmbedFromUrl(url) {
                var regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
                var matches = url.match(regExp);
                if (matches && matches[5]) {
                    return 'https://player.vimeo.com/video/' + matches[5];
                }
                return null;
            }

            function isIOSVersionBetween(start, end, includeStart, includeEnd) {
                var curVer = iOSversion();
                if (curVer != -1) {
                    var startIsValid = versionIsGreater(curVer, start);
                    if (includeStart) {
                        startIsValid = startIsValid || versionIsSame(curVer, start);
                    }

                    var endIsValid = versionIsBelow(curVer, end);
                    if (includeEnd) {
                        endIsValid = endIsValid || versionIsSame(curVer, end);
                    }

                    return startIsValid && endIsValid;
                }
                return false;
            }

            function isIOSVersionAbove(version) {
                var curVer = iOSversion();
                if (curVer != -1) {
                    return versionIsGreater(curVer, version);
                }
                return false;
            }

            function isIOSVersionOrAbove(version) {
                var curVer = iOSversion();
                if (curVer != -1) {
                    return (versionIsSame(curVer, version) || versionIsGreater(curVer, version));
                }
                return false;
            }

            function isIOSVersion(version, strict) {
                var curVer = iOSversion();
                if (curVer != -1) {
                    return versionIsSame(curVer, version, strict);
                }
                return false;
            }

            function isIOSVersionOrBelow(version) {
                var curVer = iOSversion();
                if (curVer != -1) {
                    return (versionIsSame(curVer, version) || versionIsBelow(curVer, version));
                }
                return false;
            }

            function isIOSVersionBelow(version) {
                var curVer = iOSversion();
                if (curVer != -1) {
                    return versionIsBelow(curVer, version);
                }
                return false;
            }

            function CopyClipbord(el) {
                if (isIOS()) {
                    var el = el.get(0);
                    var editable = el.contentEditable;
                    var readOnly = el.readOnly;
                    el.contentEditable = true;
                    el.readOnly = false;
                    var range = document.createRange();
                    range.selectNodeContents(el);
                    var sel = window.getSelection();
                    sel.removeAllRanges();
                    sel.addRange(range);
                    el.setSelectionRange(0, 999999);
                    el.contentEditable = editable;
                    el.readOnly = readOnly;
                } else {
                    el.select();
                }
                document.execCommand('copy');
            }
            function copyTextToClipboard(text) {
                return navigator.clipboard.writeText(text)
            }

            function clearTextSelection(el) {
                el.attr('readonly', 'readonly');
                
                if (window.getSelection) {
                    if (window.getSelection().empty) {
                        // Chrome
                        window.getSelection().empty();
                    } else if (window.getSelection().removeAllRanges) {
                        // Firefox
                        window.getSelection().removeAllRanges();
                    }
                } else if (document.selection) {
                    // IE
                    document.selection.empty();
                }
            }

            function decodeHtml(value) {
                var textArea = document.createElement("textarea");
                textArea.innerHTML = value;
                var decodedValue = textArea.value;
                textArea.remove();
                return decodedValue;
            }

            function hexToRgba(hexSource, alpha) {
                var hex = hexSource.replace('#', ''),
                    r = parseInt(hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16),
                    g = parseInt(hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16),
                    b = parseInt(hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);

                return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
            }

            function isWrapperApp() {
                return NativeHelper.isWrapperApp();
            }

            function isOldFF() {
                const match = window.navigator.userAgent.match(/Firefox\/([0-9]+)\./),
                    ver = match ? parseInt(match[1]) : 0;
                return match && ver < 61
            }

            function isIOS() {
                return (/iP(hone|od|ad)/.test(navigator.platform));
            }

            function isAndroid() {
                return navigator.userAgent.toLowerCase().indexOf('android') !== -1;
            }

            function isSafari() {
                return navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
            }

            function iOSversion() {
                if (isIOS()) {
                    // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
                    var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
                    try {
                        return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
                    } catch (ex) {
                        return -1;
                    }
                }
                return -1;
            }

            function versionIsGreater(source, compare, compareParts) {
                var s = convertVersion(source);
                var c = convertVersion(compare);

                if (!compareParts) {
                    compareParts = _.max([s.length, c.length]);
                }
                for (var i = 0; i < compareParts; i++) {
                    var sv = (s[i]) ? s[i] : 0;
                    var cv = (c[i]) ? c[i] : 0;

                    if (sv < cv)
                        return false;
                    if (sv > cv)
                        return true;
                }
                return false;
            }

            function versionIsSame(source, compare, strict, compareParts) {
                var s = convertVersion(source);
                var c = convertVersion(compare);

                if (!compareParts) {
                    compareParts = _.min([s.length, c.length]);
                }
                if (strict) {
                    compareParts = _.max([s.length, c.length]);
                }

                for (var i = 0; i < compareParts; i++) {
                    if (s[i] !== c[i]) {
                        return false;
                    }
                }
                return true;
            }

            function versionIsBelow(source, compare, compareParts) {
                var s = convertVersion(source);
                var c = convertVersion(compare);

                if (!compareParts) {
                    compareParts = _.max([s.length, c.length]);
                }
                for (var i = 0; i < compareParts; i++) {
                    var sv = (s[i]) ? s[i] : 0;
                    var cv = (c[i]) ? c[i] : 0;

                    if (sv > cv) {
                        return false;
                    }
                    if (sv < cv) {
                        return true;
                    }
                }
                return false;
            }

            function convertVersion(source) {
                if (!_.isString(source)) {
                    // convert to string
                    if (_.isArray(source)) {
                        var s = '';
                        _.forEach(source, function (v, i) {
                            if (i > 0) {
                                s += '.';
                            }
                            s += v.toString();
                        });
                        source = s;
                    } else {
                        source = source.toString();
                    }
                }

                var newSource = source.split('.');
                var arr = [];
                _.forEach(newSource, function (v) {
                    arr.push(parseInt(v, 10));
                });
                return arr;
            }

            function mailTo(email, subject, body) {
                var mailtoLink = 'mailto:' + email;

                var hasSubject = false;
                if (subject && subject.length) {
                    mailtoLink += '?subject=' + encodeURIComponent(subject);
                    hasSubject = true;
                }

                if (body && body.length) {
                    mailtoLink += (!hasSubject) ? '?' : '&';

                    var bodyEncoded = encodeURIComponent(body);
                    bodyEncoded = bodyEncoded.replace(new RegExp('%3Cbr%3E', 'g'), '%0D%0A');
                    bodyEncoded = bodyEncoded.replace(new RegExp('%3Cbr%2F%3E', 'g'), '%0D%0A');
                    bodyEncoded = bodyEncoded.replace(new RegExp('%3Cbr%20%2F%3E', 'g'), '%0D%0A');
                    mailtoLink += 'body=' + bodyEncoded;
                }

                window.location.href = mailtoLink;
            }

            function getFlagByCultureId(cultureId) {
                switch (cultureId) {
                    case 1:
                        return '🇬🇧';
                    case 2:
                        return '🇩🇰';
                    case 3:
                        return '🇳🇱';
                    case 4:
                        return '🇸🇪';
                    case 5:
                        return '🇳🇴';
                    case 6:
                        return '🇪🇸';
                    case 7:
                        return '🇫🇷';
                    case 8:
                        return '🇩🇪';
                    case 9:
                        return '🇮🇹';
                    case 10:
                        return '🇵🇹';
                    case 11:
                        return '🇫🇮';
                    case 12:
                        return '🇵🇱';
                    case 13:
                        return '🇧🇪';
                    case 14:
                        return '🇹🇷';
                    case 15:
                        return '🇨🇳';
                    case 16:
                        return '🇹🇭';
                    case 17:
                        return '🇰🇷';
                    case 18:
                        return '🇨🇳';
                    case 19:
                        return '🇷🇴';
                    case 20:
                        return '🇷🇺';
                    case 21:
                        return '🇧🇬';
                    case 22:
                        return '🇮🇸';
                    case 23:
                        return '🇮🇩';
                    case 24:
                        return '🇫🇴';
                    case 25:
                        return '🇯🇵';
                    case 26:
                        return '🇻🇳';
                }
            }

            function getImageVariantBySize(requiredWidth, minVariant) {
                const imageSizes = [100, 250, 600, 1500],
                    tempVariant = imageSizes.find(size => requiredWidth <= size),
                    imageVariant = tempVariant > minVariant ? tempVariant : minVariant;

                return imageVariant ? imageVariant : imageSizes[imageSizes.length - 1];
            }
        }]);
})();
