(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('trainingBadges', {
            template: require('./TrainingBadgesComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$stateParams', 'Page', TrainingBadgesController],
            bindings: {
                badges: '<'
            }
        });

    function TrainingBadgesController($stateParams, Page) {
        var ctrl = this;

        ctrl.$onInit = init;
        ctrl.showBadgeDetails = false;
        ctrl.onBadgeClick = onBadgeClick;
        ctrl.goToTraining = goToTraining;

        function init() {
            ctrl.activeBadgesCount = _.filter(ctrl.badges, {BadgeStatus: 1}).length;
        }

        function onBadgeClick(badge) {
            ctrl.showBadgeDetails = true;
            ctrl.badge = badge;
        }

        function goToTraining() {
            if (ctrl.badge.PhaseToken) {
                Page.stateGo('training', $stateParams.token, ctrl.badge.PhaseToken, Page.getPageTransition())
            }
            if (ctrl.badge.CourseModuleToken) {
                if (ctrl.badge.CourseModuleHasCourse) {
                    Page.stateGo('trainingCourseModuleMandatory', ctrl.badge.CourseModuleToken, null, Page.getPageTransition())
                } else {
                    Page.stateGo('trainingCourseModuleVoluntary', ctrl.badge.CourseModuleToken, null, Page.getPageTransition())
                }
            }
            if (ctrl.badge.CourseToken) {
                Page.stateGo('trainingCourse', ctrl.badge.CourseToken, null, Page.getPageTransition())
            }
        }
    }
})();