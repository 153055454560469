(() => {
    'use strict';

    angular
        .module('App.Survey')
        .config(['$stateProvider', ($stateProvider) => {
            $stateProvider
                .state('surveySingleDepartment', {
                    parent: 'base',
                    url: '/survey-single-department/:token/:extra?tab?expired',
                    template: `
                        <survey-page
                            class="survey view-page"
                            survey-data="surveyData"
                            department-token="departmentToken"
                            is-single-department="true"
                        ></survey-page>`,
                    params: {
                        backState: null,
                        data: {}
                    },
                    resolve: {
                        surveyData: ['$stateParams', '$http', function ($stateParams, $http) {
                            if ($stateParams.data && !_.isEmpty($stateParams.data) && $stateParams.data.SurveyToken) {
                                return {
                                    data: $stateParams.data
                                };
                            } else {
                                const url = `/Survey/GetSurvey/${$stateParams.token}${$stateParams.expired !== undefined ? ('?isExpired=' + $stateParams.expired) : ''}`
                                return $http.get(url);
                            }
                        }]
                    },
                    controller: 'SurveySingleDepartmentPageController'
                });
        }])
        .controller('SurveySingleDepartmentPageController', ['$scope', '$stateParams', 'events', 'Page', 'surveyData', SurveySingleDepartmentPageController]);

    function SurveySingleDepartmentPageController($scope, $stateParams, events, Page, surveyData) {
        $scope.surveyToken = $stateParams.token;
        $scope.surveyInstanceToken = surveyData.data.SurveyInstanceToken;
        $scope.departmentToken = $stateParams.extra;
        $scope.surveyData = surveyData.data;
        
        const accountModuleToken = surveyData.data.AccountModuleToken;

        if ($scope.surveyData.PageTitle) {
            Page.setTitle($scope.surveyData.PageTitle);
        }

        if ($stateParams.backState !== null) {
            Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra, null, $stateParams.backState.data, true, $stateParams.backState.restParams);
        } else {
            Page.showBackButton('survey', accountModuleToken, $stateParams.token, null,  $scope.surveyData.data, true, {tab: 'manager', expired: $stateParams.expired});
        }

        $scope.$on(events.DEPARTMENT_CHANGED, () => Page.stateReload());
    }
})();