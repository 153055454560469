(() => {
    'use strict';

    angular
        .module('App.Social')
        .factory('SocialPostCreateService', ['$http', '$filter', 'SelectListService', SocialPostCreateService]);

    function SocialPostCreateService($http, $filter, SelectListService) {

        return {
            createPost: createPost,
            editPost: editPost,
            showSocialGroupChange: showSocialGroupChange
        }

        function createPost(model) {
            return post(model, true, null)
        }

        function editPost(postToken, model) {
            return post(model, false, postToken)
        }

        function post(model, isCreate, postToken) {
            const url = isCreate ? '/Social/Post' : '/Social/EditPost/' + postToken;
            
            return $http.post(url, model).then(resp => resp)
        }

        function showSocialGroupChange(socialGroups, selectedGroupId) {
            const selectOptions = [];

            socialGroups.forEach(group => {
                if (group.AllowPosting) {
                    selectOptions.push({
                        text: selectCurrentLocale(group.TranslatableEntity).Title,
                        value: group.SocialGroupId,
                        selected: (group.SocialGroupId === selectedGroupId)
                    });
                }
            })

            const selectList = SelectListService.create({
                title: $filter('translate')('SOCIAL.CREATE_POST.SOCIALGROUP'),
                options: selectOptions
            });

            // Show Select
            return selectList.show().then(data => data);
        }
        
        function selectCurrentLocale(translatableEntity) {
            return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale;
        }
    }
})();