(() => {
    'use strict';

    angular
        .module('App.Catalog')
        .component('categoriesList', {
            template: require('./CategoriesListComponent.html'),
            controllerAs: 'ctrl',
            bindings: {
                goToCategory: '<',
                categories: '<'
            }
        })
})();