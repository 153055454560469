(function () {
    'use strict';

    angular
        .module('App.Elements')
        .component('autocomplete', {
            template: require('./AutocompleteComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', '$element', '$document', AutocompleteController],
            bindings: {
                items: '<',
                findProperties: '<',
                subtitleProperties: '<',
                defaultItem: '<',
                showIcon: '<',
                placeholder: '@',
                notFoundText: '@',
                showItemsCount: '<',
                required: '<',
                reset: '=',
                onChange: '<',
                isSingle: '<',
                disabled: '<',
            },
            require: {
                ngModelCtrl: 'ngModel'
            }
        });

    function AutocompleteController($timeout, $element, $document) {
        var ctrl = this,
            selectedItems = [],
            outsideClickListenerEnabled = false;

        ctrl.PERMISSIONS_TYPE = {
            none: null,
            departments: 1,
            userGroups: 2,
            departmentsAndUserGroups: 3,
            departmentsOrUserGroups: 4
        }

        ctrl.$onInit = init;
        ctrl.$onDestroy = onDestroy;
        ctrl.reset = reset;
        ctrl.selectItem = selectItem;
        ctrl.removeItem = removeItem;
        ctrl.findItem = findItem;
        ctrl.showAll = showAll;

        function init() {
            ctrl.required && setValidation();
            generateSearchStrings(ctrl.items);
            $timeout(() => {
                if (!ctrl.ngModelCtrl.$viewValue || _.get(_.head(ctrl.ngModelCtrl.$viewValue), 'IsEveryoneRole')) {
                    reset();
                } else {
                    selectedItems = [...ctrl.ngModelCtrl.$viewValue]
                }
            });
        }

        function setValidation() {
            ctrl.ngModelCtrl.$validators.isEmpty = (modelValue, viewValue) => {
                const value = modelValue || viewValue;
                return value?.length > 0
            }
        }

        function selectItem(item) {
            if (_.indexOf(selectedItems, item) === -1) {

                // replace item for case when only single choice allowed
                if (ctrl.isSingle) {
                    selectedItems = [item];
                } else {
                    selectedItems.push(item);
                }

                ctrl.ngModelCtrl.$setViewValue([...selectedItems]);
                closeAutocomplete();
                ctrl.onChange && ctrl.onChange();
            }
        }

        function removeItem(item) {
            selectedItems = selectedItems.filter(val => val !== item);
            ctrl.ngModelCtrl.$setViewValue([...selectedItems]);
            if (!ctrl.required && ctrl.defaultItem && selectedItems.length === 0) {
                ctrl.ngModelCtrl.$setViewValue([ctrl.defaultItem]);
            }
            ctrl.onChange && ctrl.onChange();
        }

        function findItem(searchText) {
            if (searchText === '') {
                closeAutocomplete();
                return false;
            }

            var filterItems = _.filter(ctrl.items, function (item) {
                return item.visibleString.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
                    && filterBySelectedItems(item, ctrl.ngModelCtrl.$viewValue)
                    && !item.isDefault && !item.IsEveryoneRole;
            });

            var subtitleFilterItems = _.filter(ctrl.items, function (item) {
                return item.subtitle.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
                    && filterBySelectedItems(item, ctrl.ngModelCtrl.$viewValue)
                    && !item.isDefault && !item.IsEveryoneRole;
            });

            if (subtitleFilterItems && subtitleFilterItems.length) {
                subtitleFilterItems = _.filter(subtitleFilterItems, function (item) {
                    return (_.indexOf(filterItems, item) < 0);
                });
                filterItems = _.concat(filterItems, subtitleFilterItems);
            }

            ctrl.visibleItems = ctrl.showItemsCount ? _.take(filterItems, ctrl.showItemsCount) : filterItems;

            if (ctrl.visibleItems.length) {
                onMenuOpen();
            }
        }

        function showAll() {
            ctrl.visibleItems = _.filter(ctrl.items, function (item) {
                return filterBySelectedItems(item, ctrl.ngModelCtrl.$viewValue)
                    && !item.isDefault && !item.IsEveryoneRole;
            });

            if (ctrl.visibleItems.length) {
                onMenuOpen();
            }
        }

        function closeAutocomplete() {
            $timeout(() => {
                ctrl.visibleItems = [];
                ctrl.searchWord = null;
                onMenuClose();
            })
        }

        function generateSearchStrings(items) {
            var separator = '-';

            _.each(items, function (item) {
                var itemPropertiesString = '';
                var subtitle = '';

                _.each(ctrl.findProperties, function (property, key) {
                    if (item[property].length) {
                        itemPropertiesString += item[property] + (ctrl.findProperties.length - 1 !== key ? ' ' + separator + ' ' : '')
                    }
                });

                if (ctrl.subtitleProperties) {
                    _.each(ctrl.subtitleProperties, function (property, key) {
                        if (item[property].length) {
                            subtitle += item[property] + (ctrl.subtitleProperties.length - 1 !== key ? ' ' + separator + ' ' : '')
                        }
                    });
                }

                item.subtitle = subtitle;
                item.visibleString = itemPropertiesString;
            });
        }

        function filterBySelectedItems(item, selectedItems) {
            return _.findIndex(selectedItems, item) === -1
        }

        function reset() {
            selectedItems = [];
            if (ctrl.required && ctrl.ngModelCtrl.$viewValue && ctrl.ngModelCtrl.$viewValue.length) {
                selectedItems.push(ctrl.ngModelCtrl.$viewValue[0]);
            }
            ctrl.ngModelCtrl.$setViewValue(selectedItems);

            if (ctrl.defaultItem && selectedItems.length === 0) {
                if (ctrl.defaultItem.isDefault) {
                    ctrl.defaultItem.isDefault = true;
                } else if (ctrl.defaultItem.IsEveryoneRole) {
                    ctrl.defaultItem.IsEveryoneRole = true;
                } else {
                    ctrl.defaultItem.isDefault = false;
                    ctrl.defaultItem.IsEveryoneRole = false;
                }
                generateSearchStrings([ctrl.defaultItem]);
                ctrl.ngModelCtrl.$setViewValue([ctrl.defaultItem]);
            }
        }

        function clickOutsideHandler(event) {
            const isClickedElementChildOfPopup = $element
                .find('.select-area')
                .find(event.target)
                .length > 0;

            if (isClickedElementChildOfPopup) return;

            closeAutocomplete();
        }

        function onMenuOpen() {
            if (outsideClickListenerEnabled) return;
            $document.bind('click', clickOutsideHandler);
            outsideClickListenerEnabled = true;
        }

        function onMenuClose() {
            $document.unbind('click', clickOutsideHandler);
            outsideClickListenerEnabled = false;
        }

        function onDestroy() {
            // make sure that all listeners disabled
            if (outsideClickListenerEnabled) {
                $document.unbind('click', clickOutsideHandler);
            }
        }
    }
})();
