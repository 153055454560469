(() => {
    'use strict';

    const template =
        `<training-module-page
            class="view-page"
            module="ctrl.module"
            lessons="ctrl.lessons"
            course-token="ctrl.courseToken"
            module-token="ctrl.moduleToken"
            performance-ranges="ctrl.performanceRanges"    
         ></training-module-page>`

    angular
        .module('App.Training')
        .config(['$stateProvider', $stateProvider => {
            $stateProvider
                .state('trainingCourseModuleMandatory', {
                    parent: 'base',
                    url: '/training/courseModuleMandatory/:token/:extra',
                    resolve: {
                        trainingData: ['$http', '$stateParams', '$state', 'base', function ($http, $stateParams, $state, base) {
                            if (base) {
                                if ($stateParams.extra) {
                                    return $http.get('/Training/CourseModule/Get/' + $stateParams.token + '?courseToken=' + $stateParams.extra);
                                } else {
                                    return $http.get('/Training/CourseModule/Get/' + $stateParams.token);
                                }
                            }
                        }]
                    },
                    params: {
                        backState: null,
                        data: {}
                    },
                    template: template,
                    controller: 'TrainingCourseModuleController as ctrl'
                })
                .state('trainingCourseModuleVoluntary', {
                    parent: 'base',
                    url: '/training/courseModuleVoluntary/:token/:extra',
                    params: {
                        isVoluntary: true,
                        backState: null,
                        data: {}
                    },
                    resolve: {
                        trainingData: ['$http', '$stateParams', '$state', 'base', function ($http, $stateParams, $state, base) {
                            if (base) {
                                return $http.get('/Training/CourseModule/Get/' + $stateParams.token);
                            }
                        }]
                    },
                    template: template,
                    controller: 'TrainingCourseModuleController as ctrl'
                })
        }])
        .controller('TrainingCourseModuleController', ['$scope', '$state', '$stateParams', 'Page', 'trainingData', 'events',
            function ($scope, $state, $stateParams, Page, trainingData, events) {
                const ctrl = this;

                Page.setTitle(trainingData.data.PageTitle);
                ctrl.moduleToken = trainingData.data.AccountModuleToken;

                if ($stateParams.backState) {
                    Page.showBackButton($stateParams.backState.state, $stateParams.backState.token, $stateParams.backState.extra, null, $stateParams.backState.options);
                } else {
                    if ($stateParams.isVoluntary) {
                        Page.showBackButton('trainingVoluntary', trainingData.data.AccountModuleToken);
                    } else if (trainingData.data.CourseToken) {
                        Page.showBackButton('trainingCourse', trainingData.data.CourseToken);
                    } else {
                        Page.showBackButton('training', trainingData.data.AccountModuleToken);
                    }
                }

                $scope.$on(events.DEPARTMENT_CHANGED, () => {
                    Page.stateGoBack('training', trainingData.data.AccountModuleToken);
                });

                ctrl.lessons = trainingData.data.Lessons;
                ctrl.module = trainingData.data.Module;
                ctrl.courseToken = trainingData.data.CourseToken;
                ctrl.performanceRanges = trainingData.data.PerformanceRanges;
            }
        ]);


})();