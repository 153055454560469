(() => {
    angular
        .module('App.Survey')
        .component('storeManagerVew', {
            template: require('./StoreManagerVewComponent.html'),
            controllerAs: 'ctrl',
            controller: ['SurveyService', StoreManagerVewController],
            bindings: {
                isLoaded: '<',
                surveyToken: '<',
                surveyInstanceToken: '<',
                departmentToken: '<',
                drivers: '<',
                filters: '<',
                isDirectReports: '<'
            }
        })

    function StoreManagerVewController(SurveyService) {
        const ctrl = this;

        ctrl.showQuestionStatisticPopup = showQuestionStatisticPopup;
        ctrl.getDriverIcon = SurveyService.getDriverIcon;
        ctrl.getRatingClass = SurveyService.getRatingClass;

        function showQuestionStatisticPopup(question, index) {
            ctrl.questionStatisticPopup && ctrl.questionStatisticPopup.open({...question, index});
        }
    }
})();