(() => {
    'use strict';

    angular
        .module('App.Social')
        .component('socialPostCreate', {
            template: require('./SocialPostCreateComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$rootScope', '$timeout', '$element', 'Page', 'SocialPostCreateService',
                'ToastFactory', 'TranslationService', 'ConfirmPopupService',
                'LanguageSelectorService', 'RichMentionService', 'ContentSafetyWarningService', SocialPostCreateController],
            bindings: {
                editablePost: '<',
                settings: '<',
                groups: '<',
            }
        })

    function SocialPostCreateController($scope, $rootScope, $timeout, $element, Page, SocialPostCreateService,
                                        ToastFactory, TranslationService, ConfirmPopupService, LanguageSelectorService,
                                        RichMentionService, ContentSafetyWarningService) {
        const ctrl = this;
        let postChangeWatcher, postModelChangeWatcher, popup, socialGroups = [];

        ctrl.$onInit = init;
        ctrl.onOpen = onOpen;
        ctrl.close = close;
        ctrl.postSubmit = postSubmit;
        ctrl.postMediaUploaded = postMediaUploaded;
        ctrl.removeMedia = removeMedia;
        ctrl.changeSocialGroup = changeSocialGroup;
        ctrl.getTranslation = getTranslation;
        ctrl.translateDisabled = translateDisabled;
        ctrl.onLanguagesLoaded = onLanguagesLoaded;
        ctrl.getUserLanguage = getUserLanguage;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.onClose = onClose;
        ctrl.requireUserLanguage = !Page.getSettings().CulturesRework;

        function init() {
            ctrl.model = {
                text: '',
                mediaList: [],
                media: [],
                selectedLanguages: [],
                socialGroupId: null,
                socialGroupName: null,
                accountModuleToken: ctrl.settings.AccountModuleToken
            };

            if (ctrl.groups?.length) {
                socialGroups = ctrl.groups.filter(group => group.SocialGroupId);
            }

            ctrl.isPosting = false;
            ctrl.disableSocialGroup = false;
            ctrl.mode = ctrl.editablePost ? 'edit' : 'create';

            if (ctrl.settings.MultipleLanguagesEnabled) {
                ctrl.getLanguages = LanguageSelectorService.getLanguages(ctrl.settings.AccountModuleToken, ctrl.editablePost?.SocialPostToken);
            } else {
                if (ctrl.editablePost) {
                    iniPostChangeWatcher();
                }
                initPostModelChangeWatcher();
            }

            if (ctrl.settings.SocialGroupId) {
                ctrl.model.socialGroupId = ctrl.settings.SocialGroupId;
                ctrl.model.socialGroupName = ctrl.settings.SocialGroupName;
                ctrl.disableSocialGroup = true;
            }

            if (ctrl.editablePost) {
                const groupTranslatableEntity = ctrl.editablePost.SocialGroupTranslatableEntity;
                const translatableEntity = ctrl.editablePost.TranslatableEntity;
                
                ctrl.isChanged = false;
                ctrl.model.socialGroupId = ctrl.editablePost.SocialGroupId;
                
                if (translatableEntity) {
                    ctrl.model.text = translatableEntity._currentCulture?.Text ?? getCurrentLocale(translatableEntity);
                }
                
                if (groupTranslatableEntity) {
                    ctrl.model.socialGroupName = groupTranslatableEntity._currentCulture?.Title ?? getCurrentLocale(groupTranslatableEntity);
                }
                
                ctrl.model.media = _.clone(ctrl.editablePost.MediaId);

                if (ctrl.settings.PollsEnabled && ctrl.settings.AllowCreatingPolls && ctrl.editablePost.PollData) {
                    ctrl.model.PollDeadline = ctrl.editablePost.PollData.PollDeadline;
                    ctrl.model.UsersCanAddPollOptions = ctrl.editablePost.PollData.UsersCanAddPollOptions;
                    ctrl.model.AllowPollMultiSelection = ctrl.editablePost.PollData.AllowPollMultiSelection;
                    ctrl.model.PollId = ctrl.editablePost.PollData.PollId;
                }

                if (ctrl.editablePost.selectedLanguages?.length) {
                    ctrl.model.selectedLanguageIndex = 0;
                    ctrl.model.selectedLanguages = ctrl.editablePost.selectedLanguages.map(language => {
                        language.text = language.Text;
                        if (language.PollOptions) {
                            language.PollOptions.forEach((option, key) => {
                                option.PollOptionId = key
                            });
                        }

                        return language
                    });
                } else {
                    ctrl.model.PollTitle = ctrl.editablePost.PollTitle;
                    ctrl.model.PollOptions = ctrl.editablePost.PollOptions;
                    ctrl.model.text = ctrl.editablePost.SourceText;
                }
                ctrl.disableSocialGroup = true;
            }
            ctrl.translatedText = _.cloneDeep(ctrl.model?.selectedLanguages)
            ctrl.translationExcludedWords = [];
        }
        
        function getCurrentLocale(translatableEntity) {
            return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale;
        }

        function onPopupRegistered(popup) {
            popup.open();
        }

        function onClose() {
            $rootScope.disableVisibilitychange = false;
        }

        function onOpen(data, popupCtrl) {
            popup = popupCtrl;
        }

        function close() {
            postChangeWatcher && postChangeWatcher();
            ctrl.onClose && ctrl.onClose();
            popup.remove();
            $element.remove();
        }

        function postSubmit() {
            if (!ctrl.isPosting && validatePost(ctrl.model)) {
                ctrl.isPosting = true;

                if (ctrl.isPostChanged) {
                    ConfirmPopupService
                        .open({
                            message: 'SOCIAL.CREATE_POST.POST_CHANGED',
                            yesText: 'YES',
                            noText: 'NO'
                        })
                        .then(postSubmitRequest)
                        .catch(() => {
                            ctrl.isPosting = false;
                        })
                } else {
                    postSubmitRequest();
                }
            }
        }

        function postSubmitRequest() {
            const action = ctrl.mode === 'edit' ? editPost : createPost;

            action(getPostModel(ctrl.model))
                .then(() => {
                    close();
                    ctrl.isPosting = false;
                })
                .catch(resp => {
                    ctrl.isPosting = false;
                    if (resp?.data?.ShowContentSafetyWarning) {
                        ContentSafetyWarningService
                            .openPopup(resp.data.SeverityLevel, resp.data.Action, 'SOCIAL.EDIT', () => {
                                ctrl.model.IgnoreContentSafetyWarning = true;
                                postSubmitRequest();
                            })
                    } else {
                        ToastFactory.modelStateWarning(resp, 'SOCIAL.CREATE_POST.ERROR.RESPONSE')
                    }
                });
        }


        function createPost(model) {
            return SocialPostCreateService.createPost(model)
                .then(response => {
                    $rootScope.$broadcast('social:postCreated', response.data);
                    return response;
                });
        }

        function editPost(model) {
            return SocialPostCreateService.editPost(ctrl.editablePost.SocialPostToken, model)
                .then(response => {
                    $rootScope.$broadcast('social:postUpdated', response.data?.Post);
                    return response;
                });
        }

        function getPostModel(model) {
            const {userMentions, userGroupMentions, departmentMentions} =
                    ctrl.settings.MultipleLanguagesEnabled ?
                        RichMentionService.parseMentionsFromMultipleTexts(model.selectedLanguages.map(lang => lang.text)) :
                        RichMentionService.parseMentionsFromText(model.text),
                hashtags = ctrl.settings.MultipleLanguagesEnabled ?
                    RichMentionService.parseHashTagsFromMultipleTexts(model.selectedLanguages.map(lang => lang.text)) :
                    RichMentionService.parseHashTagsFromText(model.text);

            let postModel = {
                MediaList: model.mediaList,
                AccountModuleToken: model.accountModuleToken,
                SocialGroupId: model.socialGroupId,
                Hashtags: hashtags,
                TaggedUsers: userMentions,
                TaggedDepartments: departmentMentions,
                TaggedUserGroups: userGroupMentions,
                Translations: [],
                IgnoreContentSafetyWarning: model.IgnoreContentSafetyWarning
            }

            // Convert Media to MediaList
            postModel.mediaList = [];
            for (let i = 0; i < model.media.length; i++) {
                postModel.mediaList.push(model.media[i].MediaId);
            }

            if (ctrl.settings.PollsEnabled && ctrl.settings.AllowCreatingPolls) {
                postModel.PollId = model.PollId;
                postModel.AllowPollMultiSelection = model.AllowPollMultiSelection;
                postModel.UsersCanAddPollOptions = model.UsersCanAddPollOptions;
                postModel.PollDeadline = model.PollDeadline ?
                    moment(model.PollDeadline).format('YYYY-MM-DD[T]HH:mm:ss') : null;
            }

            if (ctrl.settings.MultipleLanguagesEnabled) {
                model.selectedLanguages.forEach(language => {
                    const translationObj = {}
                    if (language.text) {
                        translationObj.AutoTranslated = language.AutoTranslated;
                        translationObj.Text = ctrl.settings.HashtagsEnabled
                            ? RichMentionService.parseNewHashtags(language.text)
                            : language.text;
                    }
                    if (ctrl.settings.PollsEnabled && ctrl.settings.AllowCreatingPolls) {
                        if (language.PollTitle) {
                            translationObj.PollTitle = language.PollTitle
                            translationObj.PollOptions = language.PollOptions
                        }
                    }

                    if (language.text || language.PollTitle) {
                        translationObj.CultureId = language.CultureId;
                    }

                    !_.isEmpty(translationObj) && postModel.Translations.push(translationObj)
                })
            } else {
                postModel.Text = ctrl.settings.HashtagsEnabled
                    ? RichMentionService.parseNewHashtags(model.text)
                    : model.text;

                if (ctrl.settings.PollsEnabled && ctrl.settings.AllowCreatingPolls) {
                    postModel.PollTitle = model.PollTitle;
                    postModel.PollOptions = model.PollOptions;
                }
            }

            return postModel;
        }

        function validatePost(model) {
            var errors = [];

            if (ctrl.settings.PollsEnabled) {
                if (!ctrl.settings.MultipleLanguagesEnabled && !model.text?.length && !model.PollOptions) {
                    errors.push('SOCIAL.CREATE_POST.ERROR.TEXT_POLL');
                }
                if (ctrl.settings.MultipleLanguagesEnabled && !validateMultipleLanguages() && !getUserLanguage().PollTitle && !ctrl.editablePost) {
                    errors.push('SOCIAL.CREATE_POST.ERROR.TEXT_POLL');
                }
            } else {
                if (!ctrl.settings.MultipleLanguagesEnabled && !model.text.length) {
                    errors.push('SOCIAL.CREATE_POST.ERROR.TEXT');
                }
                if (ctrl.settings.MultipleLanguagesEnabled && !validateMultipleLanguages() && !ctrl.editablePost) {
                    errors.push('SOCIAL.CREATE_POST.ERROR.TEXT');
                }
            }
            if (ctrl.settings.PollsEnabled) {
                if (!ctrl.settings.MultipleLanguagesEnabled && model.PollOptions) {
                    if (!isPollFilled(model)) {
                        errors.push('SOCIAL.CREATE_POST.ERROR.POLL')
                    }
                }
                if (ctrl.settings.MultipleLanguagesEnabled && getUserLanguage().PollOptions) {
                    if (!isEveryLanguagePollFilled(model)) {
                        errors.push('SOCIAL.CREATE_POST.ERROR.POLL')
                    }
                }
            }

            if (ctrl.settings.IsSharedView && !(model.socialGroupId)) {
                errors.push('SOCIAL.CREATE_POST.ERROR.SOCIALGROUP');
            }

            // Show first error
            if (!_.isEmpty(errors)) {
                ToastFactory.warningTranslated(_.first(errors));
            }

            // Return validation
            return !(errors.length);
        }

        function isPollFilled(model) {
            var isEveryOptionFilled = model.PollOptions.every(opt => opt.Text && opt.Text.length)

            return model.PollTitle && model.PollTitle.length && isEveryOptionFilled;
        }

        function isEmptyPoll(model) {
            const isEveryOptionEmpty = model.PollOptions.every(opt => !opt.Text || opt.Text.length === 0)

            return (!model.PollTitle || model.PollTitle.length === 0) && isEveryOptionEmpty
        }

        function isEveryLanguagePollFilled(model) {
            return model.selectedLanguages.every(lang => {
                if (lang.text) {
                    return isPollFilled(lang)
                } else {
                    if (!isEmptyPoll(lang)) {
                        return isPollFilled(lang)
                    } else {
                        return true
                    }
                }
            })
        }

        function validateMultipleLanguages() {
            if (Page.getSettings().CulturesRework) {
                // require any language
                return _.findIndex(ctrl.model.selectedLanguages, o => {
                    var text = _.get(o, 'text');
                    return (text && text.length);
                }) >= 0;
            } else {
                // require user language
                return _.get(getUserLanguage(), 'text');
            }
        }

        function postMediaUploaded(media) {
            ctrl.model.media.push(media);
        }

        function removeMedia(mediaToRemove) {
            ctrl.model.media = ctrl.model.media.filter(media => media.MediaId === mediaToRemove.MediaId);
        }

        function changeSocialGroup() {
            if (!ctrl.disableSocialGroup && ctrl.settings.IsSharedView) {
                SocialPostCreateService
                    .showSocialGroupChange(socialGroups, ctrl.model.socialGroupId)
                    .then(({value, text}) => {
                        ctrl.model.socialGroupId = value;
                        ctrl.model.socialGroupName = text;
                    });
            }
        }

        function onLanguagesLoaded() {
            if (ctrl.editablePost) {
                iniPostChangeWatcher();
            }
            initPostModelChangeWatcher();

            $timeout(() => {
                if (ctrl.settings.MultipleLanguagesEnabled && ctrl.model.selectedLanguages.length) {
                    $scope.$watchCollection(() => {
                        return _.get(ctrl.model.selectedLanguages[ctrl.model.selectedLanguageIndex], 'PollOptions')
                    }, (newVal, oldVal) => {
                        if ((newVal && newVal.length) !== (oldVal && oldVal.length)) {
                            updatePolls(ctrl.model.selectedLanguages[ctrl.model.selectedLanguageIndex]);
                        }
                    });
                    $scope.$watchCollection(() => {
                        return ctrl.model.selectedLanguages
                    }, (newVal, oldVal) => {
                        if ((newVal && newVal.length) !== (oldVal && oldVal.length)) {
                            updatePolls(ctrl.model.selectedLanguages[ctrl.model.selectedLanguageIndex]);
                        }
                    });
                }
            })


            $scope.$watch(() => {
                    return ctrl.model.selectedLanguages[ctrl.model.selectedLanguageIndex];
                },
                (newVal) => {
                    const item = ctrl.translatedText?.find((item) => item.CultureId === newVal?.CultureId);

                    ctrl.translationButtonDisabled = (newVal?.text ?? '').trim() === (item?.text ?? '').trim() || ctrl.model.selectedLanguages?.length < 2;

                    if (newVal && !ctrl.translationButtonDisabled) {
                        ctrl.model.selectedLanguages[ctrl.model.selectedLanguageIndex].AutoTranslated = ctrl.translationButtonDisabled;
                    }
                }, true);
        }

        function initPostModelChangeWatcher() {
            let initializing = true;

            $timeout(() => {
                postModelChangeWatcher = $scope.$watch('ctrl.model', () => {
                    if (initializing) {
                        $timeout(() => {
                            initializing = false;
                        });
                    } else {
                        ctrl.isModelChanged = true;
                        postModelChangeWatcher();
                    }
                }, true)
            })
        }

        function iniPostChangeWatcher() {
            let initializing = true;

            $timeout(() => {
                postChangeWatcher = $scope.$watch(getWatchTarget, () => {
                    if (initializing) {
                        $timeout(() => {
                            initializing = false;
                        });
                    } else {
                        ctrl.isPostChanged = true;
                        postChangeWatcher();
                    }
                }, true)
            })

            function getWatchTarget() {
                if (ctrl.settings.MultipleLanguagesEnabled) {
                    return ctrl.model.selectedLanguages.map(lang => ({
                        PollTitle: lang.PollTitle,
                        PollOptions: lang.PollOptions,
                        text: lang.text
                    }))
                } else {
                    return {
                        text: ctrl.model.text,
                        PollTitle: ctrl.model.PollTitle,
                        PollOptions: ctrl.model.PollOptions
                    }
                }
            }
        }

        function translateDisabled() {
            let selectedLanguage = ctrl.model.selectedLanguages[ctrl.model.selectedLanguageIndex];

            return ctrl.model.selectedLanguages.length === 1 || ctrl.translationButtonDisabled ||
                (!selectedLanguage.text && !selectedLanguage.PollTitle);
        }

        function isShowTranslationChangeWarning() {
            return ctrl.model.selectedLanguages
                .some(lang => {
                    const item = ctrl.translatedText?.find((item) => item.CultureId === lang.CultureId);
                    return lang.text?.trim() !== item?.text?.trim();
                })
        }


        function getTranslation() {
            if (ctrl.translatedText?.length && isShowTranslationChangeWarning()) {
                const currentCultureId = ctrl.model.selectedLanguages[ctrl.model.selectedLanguageIndex]?.CultureId;
                const languages = ctrl.model.selectedLanguages
                    .filter(culture => culture.AllowAutoTranslation && culture.CultureId !== currentCultureId)
                    .map(culture => culture.Name).join(', ');
                TranslationService.confirmTranslations(languages).then(translate)
            } else {
                translate();
            }
        }


        function translate() {
            TranslationService
                .translateSocial(ctrl.model.accountModuleToken, ctrl.model.selectedLanguages, ctrl.model.selectedLanguageIndex)
                .then(({translations, excludedWords}) => {
                    ctrl.model.selectedLanguages = ctrl.model.selectedLanguages.map(language => ({
                        ...language,
                        AutoTranslated: false
                    }));
                    if (translations?.length) {
                        translations.forEach(translation => updateTranslations(translation, getUserLanguage()));
                        ctrl.translationButtonDisabled = true;
                    }
                    ctrl.translationExcludedWords = excludedWords;
                })
        }

        function updateTranslations(translation, userLanguage) {
            const selectedLanguageItem =
                ctrl.model.selectedLanguages.find(lng => lng.CultureId === translation.CultureId);

            selectedLanguageItem.AutoTranslated = true;
            selectedLanguageItem.text = translation.Text;

            if (userLanguage.PollTitle && userLanguage.PollTitle.length) {
                selectedLanguageItem.PollTitle = translation.PollTitle;
            }
            if (userLanguage.PollOptions && userLanguage.PollOptions.length) {
                selectedLanguageItem.PollOptions =
                    translation.PollOptions.map((option, key) => {
                        option.PollOptionId = key
                        return option;
                    });
            }

            ctrl.translatedText = _.cloneDeep(ctrl.model.selectedLanguages);
            ctrl.model.selectedLanguages[ctrl.model.selectedLanguageIndex].AutoTranslated = false;
        }

        function getUserLanguage() {
            return ctrl.model.selectedLanguages[ctrl.model.selectedLanguageIndex] ||
                ctrl.model.selectedLanguages.find(lng => lng.userLanguage) ||
                ctrl.model.selectedLanguages[0];
        }

        function updatePolls(currentLanguage) {
            if (currentLanguage && currentLanguage.PollOptions) {
                ctrl.model.selectedLanguages.forEach(language => {
                    if (!language.PollOptions) {
                        language.PollOptions = [{
                            Text: '',
                            PollOptionId: 0
                        }]
                    }
                    if (language.PollOptions.length > currentLanguage.PollOptions.length) {
                        language.PollOptions.length = currentLanguage.PollOptions.length;
                    } else {
                        while (language.PollOptions.length < currentLanguage.PollOptions.length) {
                            language.PollOptions.push({
                                Text: '',
                                PollOptionId: _.last(language.PollOptions).PollOptionId + 1
                            })
                        }
                    }
                })
            }
        }
    }
})();
