(function () {
    'use strict';
    var app = angular.module('App.Filters');

    app.filter('isMedia', [
        function () {
            return function (value, mediaType) {
                if (!_.isEmpty(value)) {
                    if (value.hasOwnProperty('UrlEncoded')) {
                        if (!_.isEmpty(value.UrlEncoded)) {
                            if (mediaType) {
                                return value.MediaTypeId === mediaType;
                            } else {
                                return true;
                            }
                        }
                    }
                }
                return false;
            };
        }
    ]);
})();