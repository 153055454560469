(function () {
    'use strict';
    var app = angular.module('App.Basics');

    app.provider('$timeLocale', function () {
        this.$get = function(){
            return {
                setLocale: this.setLocale
            }
        };

        this.setLocale = function(i18n){
            var validatedLocale = i18n;
            try {
                switch (i18n) {
                    case 'no':
                        validatedLocale = 'nb';
                        break;
                    case 'zh-Hant':
                        validatedLocale = 'zh-tw';
                        break;
                    case 'zh-Hans': 
                        validatedLocale = 'zh-cn';
                        break;
                    case 'ar':
                        validatedLocale = 'ar-ma';
                        break;
                    default:
                        validatedLocale = locale;
                        break;
                }
            } catch (er) {
                validatedLocale = i18n;
            }

            moment.updateLocale(validatedLocale, {
                week: {
                    dow: 1, // set first day of week to monday
                }
            }); // change language
        };
    });
})();